import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DateContext,
  GlobalSkeletonDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import JapanMap from "./JapanMap";
import * as StatusList from "./StatusList";
import { differenceInMinutes, subDays, subHours } from "date-fns";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BarChartIcon from "@mui/icons-material/BarChart";
import BoltIcon from "@mui/icons-material/Bolt";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { statusListInfo, systemTime } from "./StatusList";
import { LanguageContext } from "../../common/localization/localization";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import {
  DEMAND_FORECAST_URL,
  DEMAND_FORECAST_PLAN_URL,
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
  DEMAND_MONITORING_URL,
  POWER_GENERATION_MONITORING_URL,
  SPOT_BIDDING_URL,
  INTRADAY_BIDDING_URL,
  BALANCING_PLAN_URL,
} from "../../../common/url";
import { useAuthedApi } from "../../../common/axios";
import { BusinessModelContext } from "../../common/customComponents/BusinessModelProvider";
import { BUSINESS_MODEL, PAPER_KIND } from "../../../common/constant";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { tenantOfBusinessModel } from "../../common/globalMenu/Header";
import { businessModelList } from "../../../types/system/Login";

const StyledPaper = styled(Paper)`
  padding: 10px;
`;

const StatusButton = styled(Button)`
  && {
    padding: 0px;
    margin: 2px;
    min-width: 48px;
  }
`;

const SingleStatusButton = styled(Button)`
  && {
    padding: 0px;
    margin: 2px 10px;
    min-width: 48px;
  }
`;

type diffTime = {
  hours: number;
  minutes: number;
};

type circularStatusInfo = {
  closingSystem: string;
  closingSystemTime: Date;
  bidAreaNum: number;
  unBidAreaNum: number;
  limitTime: diffTime;
  AreaFixStr: string;
  AreaUnFixStr: string;
};

const GET_AREA_STATUS = "/getAreaStatus";

const WHITE = "#FFFFFF";
const LIGHTGRAY = "#383838";
const BLUE = "#1976d2";
const RED = "#D32F2F";

const PlanStatusList = () => {
  //言語を切り替えるデータコンテキスト
  const { words, mode } = useContext(LanguageContext);
  // ビジネスモデルコンテキスト
  const { businessModel } = useContext(BusinessModelContext);
  // レンダリング管理
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const pickDate = useContext(DateContext);
  const { time } = useContext(TimeContext);
  const [dispStatusData, setDispDate] = useState<statusListInfo[]>([]);
  const [limitJepx, setDispLimitJepx] = useState(systemTime.balancingTimeLimit);
  const [limitOccto, setDispLimitOccto] = useState(
    systemTime.balancingTimeLimit
  );
  const [areaCount, setAreaCount] = useState(0);
  const [areaSpotFixedCount, setAreaSpotFixedCount] = useState(0);
  const [areaOcctoFixedCount, setAreaOcctoFixedCount] = useState(0);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  const initMapColor = PaletteMode === "dark" ? LIGHTGRAY : WHITE;

  // 地図の色
  const [area01, setArea01] = useState(initMapColor);
  const [area02, setArea02] = useState(initMapColor);
  const [area03, setArea03] = useState(initMapColor);
  const [area04, setArea04] = useState(initMapColor);
  const [area05, setArea05] = useState(initMapColor);
  const [area06, setArea06] = useState(initMapColor);
  const [area07, setArea07] = useState(initMapColor);
  const [area08, setArea08] = useState(initMapColor);
  const [area09, setArea09] = useState(initMapColor);
  const [area10, setArea10] = useState(initMapColor);

  const [digOpen, setDigOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const { convertIdToAreaName } = useAreaUtility();

  // 通信エラーを監視
  const api = useAuthedApi();

  useEffect(() => {
    const interval = setInterval(() => {
      upDate();
    }, 600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  //日付変更時
  useEffect(() => {
    upDate();
  }, [pickDate.pickDate]);

  useEffect(() => {
    // 画面1分更新
  }, [time]);

  useEffect(() => {
    // ライト、ダークモード切替と日本地図色切替
    initJapanMapColor();
  }, [PaletteMode]);

  /**
   * ステータス一覧更新
   */
  function upDate() {
    // エリア毎の情報を取得
    getAreaStatus();

    // スポット入札締切日時
    setDispLimitJepx(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 10:00:00"
      )
    );
    //同時同量計画提出締切日時
    setDispLimitOccto(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 12:00:00"
      )
    );
  }

  /**
   * ステータス一覧表示情報取得
   */
  function getAreaStatus() {
    setIsLoaded(false);
    api
      .get(GET_AREA_STATUS, {
        params: {
          date: pickDate.pickDate?.toLocaleString(),
        },
      })
      .then(async (res) => {
        if (res.data.result.length !== 0) {
          // 対象エリア数
          setAreaCount(res.data.result.length);
          // 入札済みエリア数
          let spotCount = 0;
          // 提出済みエリア数
          let occtoCount = 0;

          // エリアの状況によって地図の色味を更新
          for (let i = 0; i < res.data.result.length; i++) {
            const item: statusListInfo = res.data.result[i];

            // 入札済み
            if (item.spotFixedTime != null) {
              spotCount++;
            }

            if (
              item.BalancingPowerFixedTime != null ||
              item.BalancingDemandFixedTime != null
            ) {
              occtoCount++;
            }
            console.log(businessModel);

            // 該当エリアの色を変更
            MapColorChanger(item.areaId, mapColorCheck(item));

            res.data.result[i] = item;
          }

          // 該当エリア以外のマップの色を初期値に戻す
          const initColorArea = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          for (let i = 0; i < initColorArea.length; i++) {
            const areaId = ("00" + (i + 1)).slice(-2);
            for (let j = 0; j < res.data.result.length; j++) {
              if (areaId === res.data.result[j].areaId) initColorArea[i] = 1;
            }
            if (initColorArea[i] !== 1) MapColorChanger(areaId, initMapColor);
          }

          // 所属エリアの情報を取得
          setDispDate(res.data.result);
          setAreaSpotFixedCount(spotCount);
          setAreaOcctoFixedCount(occtoCount);
        } else {
          // エリア関連の登録が未設定
          setDialogMessage(words.status_not_area_setting);
          setDigOpen(true);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        // TODO エラー対応
      });
  }

  /**
   * 現在時刻と提出日時を比較 確定までのタイムリミットを算出する
   * @param diff
   * @returns
   */
  const toHourAndMinutes = (diff: number) => {
    if (diff < 0) {
      return { hours: 0, minutes: 0 };
    }
    const hour = Math.floor(diff / 60);
    const minute = diff % 60;
    return { hours: hour, minutes: minute };
  };

  const spotDiffTime: diffTime = toHourAndMinutes(
    differenceInMinutes(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 10:00:00"
      ),
      time
    )
  );

  const balancingDiffTime: diffTime = toHourAndMinutes(
    differenceInMinutes(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 12:00:00"
      ),
      time
    )
  );

  /**
   * エリアのビジネスモデルを返却する
   * @param areaId
   */
  function businessModelChecker(areaId: string) {
    let areaOfBusinessModel = "";

    for (let areaIds = 0; areaIds < businessModel.length; areaIds++) {
      if (businessModel[areaIds].areaId === areaId) {
        areaOfBusinessModel = businessModel[areaIds].businessModel;
        break;
      }
    }
    return areaOfBusinessModel;
  }

  function initJapanMapColor() {
    const initColorArea = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < initColorArea.length; i++) {
      const areaId = ("00" + (i + 1)).slice(-2);
      for (let j = 0; j < dispStatusData.length; j++) {
        if (areaId === dispStatusData[j].areaId) initColorArea[i] = 1;
      }
      if (initColorArea[i] !== 1) MapColorChanger(areaId, initMapColor);
    }
  }

  /**
   * 日本地図の色を返却する
   * @param item
   * @returns
   */
  const mapColorCheck = (item: StatusList.statusListInfo) => {
    let mapColor;

    switch (businessModelChecker(item.areaId)) {
      case BUSINESS_MODEL.IKINAI: {
        item.demandFixedTime === null ||
        item.powerFixedTime === null ||
        item.BalancingDemandFixedTime === null ||
        item.BalancingPowerFixedTime === null ||
        item.BalancingPartsFixedTime === null
          ? (mapColor = RED)
          : (mapColor = BLUE);

        break;
      }
      case BUSINESS_MODEL.IKIGAI_POWER: {
        item.powerFixedTime === null ||
        item.spotFixedTime === null ||
        item.hourFixedTime === null ||
        item.BalancingPowerFixedTime === null
          ? (mapColor = RED)
          : (mapColor = BLUE);
        break;
      }
      case BUSINESS_MODEL.MARKET_TRAN: {
        item.powerFixedTime === null ||
        item.spotFixedTime === null ||
        item.hourFixedTime === null ||
        item.BalancingPowerFixedTime === null
          ? (mapColor = RED)
          : (mapColor = BLUE);
        break;
      }
      case BUSINESS_MODEL.IKIGAI_DEMAND: {
        item.demandFixedTime === null ||
        item.spotFixedTime === null ||
        item.hourFixedTime === null ||
        item.BalancingDemandFixedTime === null ||
        item.BalancingPartsFixedTime === null
          ? (mapColor = RED)
          : (mapColor = BLUE);
        break;
      }
      default: {
        item.demandFixedTime === null ||
        item.powerFixedTime === null ||
        item.spotFixedTime === null ||
        item.hourFixedTime === null ||
        item.BalancingDemandFixedTime === null ||
        item.BalancingPowerFixedTime === null ||
        item.BalancingPartsFixedTime === null
          ? (mapColor = RED)
          : (mapColor = BLUE);
      }
    }
    return mapColor;
  };

  /**
   * 日本地図の色を変更する
   * @param areaId エリアID
   * @param mapColor 色
   */
  const MapColorChanger = async (areaId: string, mapColor: string) => {
    // 該当エリアの色を変更
    switch (areaId) {
      case "01":
        setArea01(mapColor);
        break;
      case "02":
        setArea02(mapColor);
        break;
      case "03":
        setArea03(mapColor);
        break;
      case "04":
        setArea04(mapColor);
        break;
      case "05":
        setArea05(mapColor);
        break;
      case "06":
        setArea06(mapColor);
        break;
      case "07":
        setArea07(mapColor);
        break;
      case "08":
        setArea08(mapColor);
        break;
      case "09":
        setArea09(mapColor);
        break;
      case "10":
        setArea10(mapColor);
        break;
    }
  };

  /**
   * エリアのアイコンに設定する色をビジネスモデル毎に判定
   * @returns
   */
  function areaIconCheck(statusData: StatusList.statusListInfo) {
    let locationIcon;

    switch (businessModelChecker(statusData.areaId)) {
      case BUSINESS_MODEL.IKINAI: {
        //域内 (JEPXなし)
        locationIcon = (
          <LocationOnIcon
            color={
              statusData.demandFixedTime == null ||
              statusData.powerFixedTime == null ||
              statusData.BalancingDemandFixedTime == null ||
              statusData.BalancingPowerFixedTime == null ||
              statusData.BalancingPartsFixedTime == null
                ? "error"
                : "primary"
            }
            fontSize="large"
          />
        );
        break;
      }
      case BUSINESS_MODEL.IKIGAI_POWER: {
        //域外(発電) 需要なし
        locationIcon = (
          <LocationOnIcon
            color={
              statusData.powerFixedTime == null ||
              statusData.spotFixedTime == null ||
              statusData.hourFixedTime == null ||
              statusData.BalancingPowerFixedTime == null
                ? "error"
                : "primary"
            }
            fontSize="large"
          />
        );
        break;
      }
      case BUSINESS_MODEL.IKIGAI_DEMAND: {
        //域外(需要) 発電なし
        locationIcon = (
          <LocationOnIcon
            color={
              statusData.demandFixedTime == null ||
              statusData.spotFixedTime == null ||
              statusData.hourFixedTime == null ||
              statusData.BalancingDemandFixedTime == null ||
              statusData.BalancingPartsFixedTime == null
                ? "error"
                : "primary"
            }
            fontSize="large"
          />
        );
        break;
      }
      case BUSINESS_MODEL.MARKET_TRAN: {
        //市場取引 (需要計画,需調計画,部分供給なし)
        locationIcon = (
          <LocationOnIcon
            color={
              statusData.powerFixedTime == null ||
              statusData.spotFixedTime == null ||
              statusData.hourFixedTime == null ||
              statusData.BalancingPowerFixedTime == null
                ? "error"
                : "primary"
            }
            fontSize="large"
          />
        );
        break;
      }
      default: {
        //域内+域外(発電) / 域内+域外(需要) / 域内 + 市場取引
        locationIcon = (
          <LocationOnIcon
            color={
              statusData.demandFixedTime == null ||
              statusData.powerFixedTime == null ||
              statusData.spotFixedTime == null ||
              statusData.hourFixedTime == null ||
              statusData.BalancingDemandFixedTime == null ||
              statusData.BalancingPowerFixedTime == null ||
              statusData.BalancingPartsFixedTime == null
                ? "error"
                : "primary"
            }
            fontSize="large"
          />
        );
      }
    }
    return locationIcon;
  }

  /**
   * JEPXの入札状況を返す
   * @param jepxPlanCount
   * @param jepxBidCount
   * @param jpexBidFixdTime
   * @param bidType spot onehour
   * @returns 入札状況
   */
  function jepxBidStatus(
    jepxPlanCount: number,
    jepxBidCount: number,
    jpexBidFixdTime: Date | null,
    bidType: string
  ) {
    let jepxBidStatus = 0;
    switch (bidType) {
      case "spot": {
        if (jepxBidCount === 0) {
          jepxBidStatus = 0;
        } else if (jpexBidFixdTime !== null) {
          jepxBidStatus = 2;
        } else {
          jepxBidStatus = 1;
        }
        break;
      }
      case "oneHour": {
        if (jpexBidFixdTime === null && jepxBidCount === 0) {
          jepxBidStatus = 0;
        } else if (jepxBidCount !== 0 && jepxPlanCount === 0) {
          jepxBidStatus = 1;
        } else {
          jepxBidStatus = 2;
        }
        break;
      }
    }

    // ビジネスモデル(域内自己託送)チェック

    return jepxBidStatus;
  }

  /**
   * JEPXの入札状況
   * @param isShow 表示/非表示
   * @param status 区分（未入札/約定待ち/約定済）
   * @returns チップアイコン（未入札/約定待ち/約定済）
   */
  function showStatusJepxChip(
    isShow: boolean,
    status: number,
    businessModel: string,
    chipTextColor: string
  ) {
    let text = "";
    let chipColor: any = "success";

    if (businessModel === BUSINESS_MODEL.IKINAI) {
      text = words.statusList_no_contract;
      chipColor = "default";
    } else {
      switch (status) {
        case 0: // 未入札
          text = words.unbid;
          chipColor = "error";
          break;
        case 1: // 約定待ち
          text = words.waiting_for_contract;
          chipColor = "info";
          break;
        case 2: // 約定済
          text = words.contracted;
          chipColor = "primary";
          break;
        default:
          break;
      }
    }

    if (isShow === true) {
      return (
        <Chip
          label={text}
          sx={{
            "& .MuiChip-label": {
              color: chipTextColor,
            },
          }}
          variant="filled"
          size="small"
          color={chipColor}
          style={{ fontSize: "16px", padding: "0px 4px" }}
        />
      );
    } else {
      return <></>;
    }
  }

  /**
   * OCCTOの提出状況
   * @param isShow true
   * @param status 区分（未計画/計画済/提出済）
   * @returns チップアイコン（未計画/計画済/提出済）
   */
  function showStatusOcctoChip(
    reportkind: string,
    status: string,
    businessModel: string,
    chipTextColor: string
  ) {
    let text = "";
    let chipColor: any = "success";

    switch (status) {
      case "0": // 未計画
        text = words.unplanned;
        chipColor = "error";
        break;
      case "1": // 計画済
        text = words.fixed;
        chipColor = "info";
        break;
      case "2": // 提出済
        text = words.submitted;
        chipColor = "primary";
        break;
    }

    // ビジネスモデルで表示を変更
    switch (businessModel) {
      case BUSINESS_MODEL.IKIGAI_POWER:
        // 域外発電 (需調と部分は - )
        if (reportkind === "需調" || reportkind === "部分") {
          text = words.statusList_no_contract;
          chipColor = "default";
        }
        break;
      case BUSINESS_MODEL.IKIGAI_DEMAND:
        // 域外需要
        if (reportkind === "発販") {
          text = words.statusList_no_contract;
          chipColor = "default";
        }
        break;
      case BUSINESS_MODEL.MARKET_TRAN:
        // 市場取引
        if (reportkind === "需調" || reportkind === "部分") {
          text = words.statusList_no_contract;
          chipColor = "default";
        }
        break;
    }

    return (
      <Chip
        label={text}
        variant="filled"
        size="small"
        sx={{
          "& .MuiChip-label": {
            color: chipTextColor,
          },
        }}
        color={chipColor}
        style={{ fontSize: "16px", padding: "0px 4px" }}
      />
    );
  }

  /**
   * 同時同量計画計画ステータスを返却
   * @returns
   */
  function balancingPlanStatus(statusData: StatusList.statusListInfo) {
    let balancingIcon;

    switch (businessModelChecker(statusData.areaId)) {
      //域外(発電), 市場取引
      case BUSINESS_MODEL.IKIGAI_POWER: {
        balancingIcon = (
          <TaskOutlinedIcon
            color={
              statusData.BalancingPowerSendStatus === "2" // 提出済
                ? "primary"
                : "error"
            }
            fontSize="small"
            sx={{ mr: "5px" }}
          />
        );
        break;
      }
      case BUSINESS_MODEL.MARKET_TRAN: {
        balancingIcon = (
          <TaskOutlinedIcon
            color={
              statusData.BalancingPowerSendStatus === "2" // 提出済
                ? "primary"
                : "error"
            }
            fontSize="small"
            sx={{ mr: "5px" }}
          />
        );
        break;
      }
      // 域外(需要)
      case BUSINESS_MODEL.IKIGAI_DEMAND: {
        balancingIcon = (
          <TaskOutlinedIcon
            color={
              statusData.BalancingDemandSendStatus === "2" &&
              statusData.BalancingPartsSendStatus === "2"
                ? "primary"
                : "error"
            }
            fontSize="small"
            sx={{ mr: "5px" }}
          />
        );
        break;
      }
      // その他ビジネスモデル
      default: {
        balancingIcon = (
          <TaskOutlinedIcon
            color={
              statusData.BalancingDemandSendStatus === "2" &&
              statusData.BalancingPartsSendStatus === "2" &&
              statusData.BalancingPowerSendStatus === "2"
                ? "primary"
                : "error"
            }
            fontSize="small"
            sx={{ mr: "5px" }}
          />
        );
      }
    }

    return balancingIcon;
  }

  /**
   * 同時同量計画カード背景色を返却
   */
  function balancingCardColorChange(statusData: StatusList.statusListInfo) {
    let balancingCardColor = "";

    switch (businessModelChecker(statusData.areaId)) {
      //域外(発電), 市場取引
      case BUSINESS_MODEL.IKIGAI_POWER: {
        statusData.BalancingPowerSendStatus === "2" //提出済
          ? (balancingCardColor = "#00000000")
          : PaletteMode === "dark"
          ? (balancingCardColor = "#3E2F2F")
          : (balancingCardColor = "#D32F2F0A");
        break;
      }
      case BUSINESS_MODEL.MARKET_TRAN: {
        statusData.BalancingPowerSendStatus === "2" //提出済
          ? (balancingCardColor = "#00000000")
          : PaletteMode === "dark"
          ? (balancingCardColor = "#3E2F2F")
          : (balancingCardColor = "#D32F2F0A");
        break;
      }
      case BUSINESS_MODEL.IKIGAI_DEMAND: {
        //域外(需要)
        statusData.BalancingDemandSendStatus === "2" &&
        statusData.BalancingPartsSendStatus === "2" // 提出済
          ? (balancingCardColor = "#00000000")
          : PaletteMode === "dark"
          ? (balancingCardColor = "#3E2F2F")
          : (balancingCardColor = "#D32F2F0A");
        break;
      }
      default: {
        //その他ビジネスモデル
        statusData.BalancingDemandSendStatus === "2" &&
        statusData.BalancingPartsSendStatus === "2" &&
        statusData.BalancingPowerSendStatus === "2"
          ? (balancingCardColor = "#00000000")
          : PaletteMode === "dark"
          ? (balancingCardColor = "#3E2F2F")
          : (balancingCardColor = "#D32F2F0A");
        break;
      }
    }
    return balancingCardColor;
  }

  /**
   * 契約エリア1つ カード表示
   * @param props
   * @returns
   */
  const SinglePlanStatus = (props: StatusList.statusListInfo) => {
    const languageContext = useContext(LanguageContext);
    const statusData: StatusList.statusListInfo = props;
    const navigate = useNavigate();
    const handleClickLink = (path: string) => {
      navigate(path);
    };
    const areaContext = useContext(AreaContext);

    /**
     * エリア名とアイコン作成
     */
    function areaIconColor() {
      return (
        <Grid
          item
          xs={12}
          fontSize="22px" // TODO 差分
          fontWeight={"bold"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {areaIconCheck(statusData)}
          {convertIdToAreaName(statusData.areaId)}
        </Grid>
      );
    }

    /**
     * 発電計画カード作成
     * @returns
     */
    function powerForecastCard() {
      return (
        <Grid item xs={6}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.POWER_FORECAST,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.POWER_FORECAST
                )}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <BoltIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.POWER_FORECAST,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.power_gen_plan}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
              >
                <SingleStatusButton
                  variant="outlined"
                  size="small"
                  style={{ fontSize: "16px", padding: "0px 10px" }}
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.POWER_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        POWER_GENERATION_MONITORING_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.monitoring}
                </SingleStatusButton>
                <SingleStatusButton
                  variant="outlined"
                  size="small"
                  style={{ fontSize: "16px", padding: "0px 10px" }}
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.POWER_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);                    
                    handleClickLink(
                      ".." + POWER_FORECAST_URL + "?areaId=" + statusData.areaId
                    )
                  }

                  }
                >
                  {languageContext.words.forecast}
                </SingleStatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {languageContext.words.decision_date_and_time}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.POWER_FORECAST
                )}
                sx={{ mt: "10px" }}
              >
                {statusData.powerFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      new Date(statusData.powerFixedTime)
                    )
                  : "-"}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    /**
     * 需要計画カード作成
     * @returns
     */
    function demandForecastCard() {
      return (
        <Grid item xs={6}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.DEMAND_FORECAST,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.DEMAND_FORECAST
                )}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <BarChartIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.DEMAND_FORECAST,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.demandforecast}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
              >
                <SingleStatusButton
                  variant="outlined"
                  size="small"
                  style={{ fontSize: "16px", padding: "0px 10px" }}
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.DEMAND_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        DEMAND_MONITORING_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}

                  }
                >
                  {languageContext.words.monitoring}
                </SingleStatusButton>
                <SingleStatusButton
                  variant="outlined"
                  size="small"
                  style={{ fontSize: "16px", padding: "0px 10px" }}
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.DEMAND_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        DEMAND_FORECAST_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.forecast}
                </SingleStatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {languageContext.words.decision_date_and_time}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.DEMAND_FORECAST
                )}
                sx={{ mt: "10px" }}
              >
                {statusData.demandFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      new Date(statusData.demandFixedTime)
                    )
                  : "-"}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    /**
     *
     * @returns スポット入札カード作成
     */
    function spotBiddingCard() {
      return (
        <Grid item xs={6}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.SPOT_BIDDING,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <OutlinedFlagIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.SPOT_BIDDING,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.spot_bidding}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusJepxChip(
                  true,
                  jepxBidStatus(
                    statusData.spotPlanCount,
                    statusData.spotPlanBitCount,
                    statusData.spotFixedTime,
                    "spot"
                  ),
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.SPOT_BIDDING
                  )
                )}
                <SingleStatusButton
                  variant="outlined"
                  size="small"
                  style={{ fontSize: "16px", padding: "0px 10px" }}
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.SPOT_BIDDING
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." + SPOT_BIDDING_URL + "?areaId=" + statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.bidding}
                </SingleStatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {languageContext.words.date_and_time_reflection_contract}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
                sx={{ mt: "10px" }}
              >
                {statusData.spotFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      subHours(new Date(statusData.spotFixedTime), 9)
                    )
                  : "-"}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {languageContext.words.num_of_normal_bid}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {/*空のグリッド*/}
              </Grid>
              <Grid item xs={4}>
                <LinearProgress
                  style={{
                    height: "10px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  sx={{
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: changeTheColorOfPaperTitle(
                        businessModelChecker(statusData.areaId),
                        PAPER_KIND.SPOT_BIDDING
                      ),
                    },
                  }}
                  variant="determinate"
                  value={
                    (statusData.spotPlanBitCount / statusData.spotPlanCount) *
                    100
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction={"row"}
                justifyContent={"center"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
              >
                {statusData.spotPlanBitCount}/{statusData.spotPlanCount}
              </Grid>
              <Grid item xs={4}>
                {/*空のグリッド*/}
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction={"row"}
                justifyContent={"center"}
              >
                {/*空のグリッド*/}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    /**
     * 1時間前入札カード作成
     * @returns
     */
    function oneHourBiddingCard() {
      return (
        <Grid item xs={6}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.ONEHOUR_BIDDING,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.ONEHOUR_BIDDING
                )}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <TimerOutlinedIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.ONEHOUR_BIDDING,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.one_hour_ago_bidding}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusJepxChip(
                  true,
                  jepxBidStatus(
                    statusData.hourPlanCountBeforeGC,
                    statusData.hourPlanBitCountBeforeGC,
                    statusData.hourFixedTime,
                    "oneHour"
                  ),
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.ONEHOUR_BIDDING
                  )
                )}
                <SingleStatusButton
                  variant="outlined"
                  size="small"
                  style={{ fontSize: "16px", padding: "0px 10px" }}
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.ONEHOUR_BIDDING
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        INTRADAY_BIDDING_URL +
                        "?areaId=" +
                        statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.bidding}
                </SingleStatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.date_and_time_reflection_contract}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="18px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
                sx={{ mt: "15px" }}
              >
                {statusData.hourFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      subHours(new Date(statusData.hourFixedTime), 9)
                    )
                  : "-"}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {languageContext.words.contracts_bids_num_after_gc}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {languageContext.words.contracts_bids_num_before_gc}
              </Grid>
              <Grid item xs={4}>
                <LinearProgress
                  style={{
                    height: "10px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  variant="determinate"
                  sx={{
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: changeTheColorOfPaperTitle(
                        businessModelChecker(statusData.areaId),
                        PAPER_KIND.ONEHOUR_BIDDING
                      ),
                    },
                  }}
                  value={
                    (statusData.hourPlanCountAfterGC /
                      statusData.hourPlanBitCountAfterGC) *
                    100
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction={"row"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.ONEHOUR_BIDDING
                )}
                justifyContent={"center"}
              >
                {statusData.hourPlanCountAfterGC}/
                {statusData.hourPlanBitCountAfterGC}
              </Grid>
              <Grid item xs={4}>
                <LinearProgress
                  style={{
                    height: "10px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  variant="determinate"
                  sx={{
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: changeTheColorOfPaperTitle(
                        businessModelChecker(statusData.areaId),
                        PAPER_KIND.ONEHOUR_BIDDING
                      ),
                    },
                  }}
                  value={
                    (statusData.hourPlanCountBeforeGC /
                      statusData.hourPlanBitCountBeforeGC) *
                    100
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.ONEHOUR_BIDDING
                )}
                direction={"row"}
                justifyContent={"center"}
              >
                {statusData.hourPlanCountBeforeGC}/
                {statusData.hourPlanBitCountBeforeGC}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    /**
     * 同時同量計画カード作成
     * @returns
     */
    function balancingPlanCard(statusData: StatusList.statusListInfo) {
      return (
        <Grid item xs={12}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: balancingCardColorChange(statusData),
            }}
          >
            <Grid container>
              {/**
               * 同時同量計画タイトル,アイコン
               */}
              <Grid
                item
                xs={12}
                fontSize="18px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {balancingPlanStatus(statusData)}
                {languageContext.words.simultaneous_commensurate_plan}
              </Grid>
              {/**
               * 発電販売計画 (タイトル)
               */}
              <Grid
                item
                xs={4}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {
                  languageContext.words
                    .final_confirmation_time_power_gen_and_sales_plan
                }
              </Grid>
              {/**
               * 需要調達計画 (タイトル)
               */}
              <Grid
                item
                xs={4}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {
                  languageContext.words
                    .final_confirmation_time_demand_and_procurement_plan
                }
              </Grid>
              {/**
               * 部分供給計画 (タイトル)
               */}
              <Grid
                item
                xs={4}
                fontSize="16px"
                color={"gray"}
                sx={{ mt: "15px" }}
              >
                {
                  languageContext.words
                    .final_confirmation_time_partial_supply_plan
                }
              </Grid>
              {/**
               * 発電販売計画 (ステータス, ボタン)
               */}
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                {showStatusOcctoChip(
                  "発販",
                  statusData.BalancingPowerSendStatus,
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.BALANCING_POWER
                  )
                )}

                <SingleStatusButton
                  size="small"
                  style={{ marginTop: "10px", fontSize: "16px" }}
                  disabled={
                    businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.IKIGAI_DEMAND
                      ? false
                      : true
                  }
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        POWER_FORECAST_PLAN_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.power_gen_and_sales_plan}
                </SingleStatusButton>
              </Grid>
              {/**
               * 需要調達計画 (ステータス,ボタン)
               */}
              <Grid
                container
                item
                xs={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusOcctoChip(
                  "需調",
                  statusData.BalancingDemandSendStatus,
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.BALANCING_DEMAND
                  )
                )}
                <SingleStatusButton
                  size="small"
                  style={{ marginTop: "10px", fontSize: "16px" }}
                  disabled={
                    businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.IKIGAI_POWER
                      ? businessModelChecker(statusData.areaId) !==
                        BUSINESS_MODEL.MARKET_TRAN
                        ? false
                        : true
                      : true
                  }
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        DEMAND_FORECAST_PLAN_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.demand_and_procurement_plan}
                </SingleStatusButton>
              </Grid>
              {/**
               * 部分供給計画 (ステータス, ボタン)
               */}
              <Grid
                container
                item
                xs={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusOcctoChip(
                  "部分",
                  statusData.BalancingPartsSendStatus,
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.BALANCING_PARTS
                  )
                )}
                <SingleStatusButton
                  size="small"
                  style={{ marginTop: "10px", fontSize: "16px" }}
                  disabled={
                    businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.IKIGAI_POWER
                      ? businessModelChecker(statusData.areaId) !==
                        BUSINESS_MODEL.MARKET_TRAN
                        ? false
                        : true
                      : true
                  }
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." + BALANCING_PLAN_URL + "?areaId=" + statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.simultaneous_commensurate_plan}
                </SingleStatusButton>
              </Grid>
              {/**
               * 発電販売計画 (提出日時)
               */}
              <Grid
                container
                item
                xs={4}
                fontSize="18px"
                fontWeight={"bold"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "15px",
                  mb: "10px",
                }}
              >
                {businessModelChecker(statusData.areaId) !==
                BUSINESS_MODEL.IKIGAI_DEMAND
                  ? statusData.BalancingPowerFixedTime !== null
                    ? languageContext.convertDateToLocalizedStringGoodAcu(
                        new Date(statusData.BalancingPowerFixedTime)
                      )
                    : ""
                  : ""}
              </Grid>
              {/**
               * 需要調達計画 (提出日時)
               */}
              <Grid
                container
                item
                xs={4}
                fontSize="18px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {businessModelChecker(statusData.areaId) !==
                BUSINESS_MODEL.IKIGAI_POWER
                  ? businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.MARKET_TRAN
                    ? statusData.BalancingDemandFixedTime !== null
                      ? languageContext.convertDateToLocalizedStringGoodAcu(
                          new Date(statusData.BalancingDemandFixedTime)
                        )
                      : ""
                    : ""
                  : ""}
              </Grid>
              {/**
               * 部分供給計画 (提出日時)
               */}

              <Grid
                container
                item
                xs={4}
                fontSize="18px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {businessModelChecker(statusData.areaId) !==
                BUSINESS_MODEL.IKIGAI_POWER
                  ? businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.MARKET_TRAN
                    ? statusData.BalancingPartsFixedTime !== null
                      ? languageContext.convertDateToLocalizedStringGoodAcu(
                          new Date(statusData.BalancingPartsFixedTime)
                        )
                      : ""
                    : ""
                  : ""}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    return (
      <Card variant="outlined" sx={{ maxWidth: "100%", m: "5px", p: "5px" }}>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              {areaIconColor()}
              {powerForecastCard()}
              {demandForecastCard()}
              {spotBiddingCard()}
              {oneHourBiddingCard()}
              {balancingPlanCard(statusData)}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    );
  };

  /**
   * 契約エリア複数 カード表示
   * @param props
   * @returns
   */
  const MultiPlanStatus = (props: StatusList.statusListInfo) => {
    const languageContext = useContext(LanguageContext);
    const statusData: StatusList.statusListInfo = props;
    const navigate = useNavigate();
    const handleClickLink = (path: string) => {
      navigate(path);
    };
    const areaContext = useContext(AreaContext);

    function multiAreaIconColor() {
      return (
        <Grid
          item
          xs={12}
          fontSize="18px"
          fontWeight={"bold"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {areaIconCheck(statusData)}
          {convertIdToAreaName(statusData.areaId)}
        </Grid>
      );
    }

    function multiPowerForecastCard() {
      return (
        <Grid item xs={mode === "jp" ? 6 : 12}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.POWER_FORECAST,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="14px"
                fontWeight={"bold"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.POWER_FORECAST
                )}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <BoltIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.POWER_FORECAST,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.power_gen_plan}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
              >
                <StatusButton
                  variant="outlined"
                  size="small"
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.POWER_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        POWER_GENERATION_MONITORING_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.monitoring}
                </StatusButton>
                <StatusButton
                  variant="outlined"
                  size="small"
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.POWER_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." + POWER_FORECAST_URL + "?areaId=" + statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.plan}
                </StatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.decision_date_and_time}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ m: "0px" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.POWER_FORECAST
                )}
              >
                {statusData.powerFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      new Date(statusData.powerFixedTime)
                    )
                  : "-"}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    function multiDemandForecastCard() {
      return (
        <Grid item xs={mode === "jp" ? 6 : 12}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.DEMAND_FORECAST,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.DEMAND_FORECAST
                )}
              >
                <BarChartIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.DEMAND_FORECAST,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.demand_plan}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
              >
                <StatusButton
                  variant="outlined"
                  size="small"
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.DEMAND_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        DEMAND_MONITORING_URL +
                        "?areaId=" +
                        statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.monitoring}
                </StatusButton>
                <StatusButton
                  variant="outlined"
                  size="small"
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.DEMAND_FORECAST
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        DEMAND_FORECAST_URL +
                        "?areaId=" +
                        statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.plan}
                </StatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.decision_date_and_time}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ m: "0px" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.DEMAND_FORECAST
                )}
              >
                {statusData.demandFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      new Date(statusData.demandFixedTime)
                    )
                  : "-"}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    function multiSpotBiddingCard() {
      return (
        <Grid item xs={12}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.SPOT_BIDDING,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
              >
                <OutlinedFlagIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.SPOT_BIDDING,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.spot_bidding}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusJepxChip(
                  true,
                  jepxBidStatus(
                    statusData.spotPlanCount,
                    statusData.spotPlanBitCount,
                    statusData.spotFixedTime,
                    "spot"
                  ),
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.SPOT_BIDDING
                  )
                )}
                <StatusButton
                  variant="outlined"
                  size="small"
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.SPOT_BIDDING
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." + SPOT_BIDDING_URL + "?areaId=" + statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.bidding}
                </StatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.date_and_time_reflection_contract}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ m: "0px" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
              >
                {statusData.spotFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      subHours(new Date(statusData.spotFixedTime), 9)
                    )
                  : "-"}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.num_of_normal_bid}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {/*空のグリッド*/}
              </Grid>
              <Grid item xs={4}>
                <LinearProgress
                  style={{
                    height: "10px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  variant="determinate"
                  sx={{
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: changeTheColorOfPaperTitle(
                        businessModelChecker(statusData.areaId),
                        PAPER_KIND.SPOT_BIDDING
                      ),
                    },
                  }}
                  value={
                    (statusData.spotPlanBitCount / statusData.spotPlanCount) *
                    100
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction={"row"}
                justifyContent={"center"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
              >
                {statusData.spotPlanBitCount}/{statusData.spotPlanCount}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    function multiOneHourBiddingCard() {
      return (
        <Grid item xs={12}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: changeTheColorOfPaper(
                businessModelChecker(statusData.areaId),
                PAPER_KIND.ONEHOUR_BIDDING,
                statusData,
                PaletteMode
              ),
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.ONEHOUR_BIDDING
                )}
              >
                <TimerOutlinedIcon
                  color={changeTheColorOfIcon(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.ONEHOUR_BIDDING,
                    statusData
                  )}
                  fontSize="small"
                  sx={{ mr: "5px" }}
                />
                {languageContext.words.one_hour_ago_bidding}
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                justifyContent={"end"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusJepxChip(
                  true,
                  jepxBidStatus(
                    statusData.hourPlanCountBeforeGC,
                    statusData.hourPlanBitCountBeforeGC,
                    statusData.hourFixedTime,
                    "oneHour"
                  ),
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.ONEHOUR_BIDDING
                  )
                )}
                <StatusButton
                  variant="outlined"
                  size="small"
                  disabled={changeTheDisabledOfButton(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.ONEHOUR_BIDDING
                  )}
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        INTRADAY_BIDDING_URL +
                        "?areaId=" +
                        statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.bidding}
                </StatusButton>
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.date_and_time_reflection_contract}
              </Grid>
              <Grid
                item
                xs={12}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ m: "0px" }}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.SPOT_BIDDING
                )}
              >
                {statusData.hourFixedTime !== null
                  ? languageContext.convertDateToLocalizedStringGoodAcu(
                      subHours(new Date(statusData.hourFixedTime), 9)
                    )
                  : "-"}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.contracts_bids_num_after_gc}
              </Grid>
              <Grid
                item
                xs={6}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {languageContext.words.contracts_bids_num_before_gc}
              </Grid>
              <Grid item xs={4}>
                <LinearProgress
                  style={{
                    height: "10px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  variant="determinate"
                  sx={{
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: changeTheColorOfPaperTitle(
                        businessModelChecker(statusData.areaId),
                        PAPER_KIND.ONEHOUR_BIDDING
                      ),
                    },
                  }}
                  value={
                    (statusData.hourPlanCountAfterGC /
                      statusData.hourPlanBitCountAfterGC) *
                    100
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction={"row"}
                justifyContent={"center"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.ONEHOUR_BIDDING
                )}
              >
                {statusData.hourPlanCountAfterGC}/
                {statusData.hourPlanBitCountAfterGC}
              </Grid>
              <Grid item xs={4}>
                <LinearProgress
                  style={{
                    height: "10px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                  variant="determinate"
                  sx={{
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: changeTheColorOfPaperTitle(
                        businessModelChecker(statusData.areaId),
                        PAPER_KIND.ONEHOUR_BIDDING
                      ),
                    },
                  }}
                  value={
                    (statusData.hourPlanCountBeforeGC /
                      statusData.hourPlanBitCountBeforeGC) *
                    100
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction={"row"}
                justifyContent={"center"}
                color={changeTheColorOfPaperTitle(
                  businessModelChecker(statusData.areaId),
                  PAPER_KIND.ONEHOUR_BIDDING
                )}
              >
                {statusData.hourPlanCountBeforeGC}/
                {statusData.hourPlanBitCountBeforeGC}
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    function multiBalancingPlanCard(statusData: StatusList.statusListInfo) {
      return (
        <Grid item xs={12}>
          <StyledPaper
            variant="outlined"
            sx={{
              backgroundColor: balancingCardColorChange(statusData),
            }}
          >
            <Grid container>
              {/*タイトル*/}
              <Grid
                item
                xs={6}
                fontSize="14px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {balancingPlanStatus(statusData)}
                {languageContext.words.simultaneous_commensurate_plan}
              </Grid>
              {/*発電販売タイトル*/}
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {
                  languageContext.words
                    .final_confirmation_time_power_gen_and_sales_plan
                }
              </Grid>
              {/*発電販売 ステータス, 提出日時, ボタン*/}
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                {showStatusOcctoChip(
                  "発販",
                  statusData.BalancingPowerSendStatus,
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.BALANCING_POWER
                  )
                )}
                <Grid
                  item
                  fontWeight={"bold"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {statusData.BalancingPowerFixedTime !== null
                    ? languageContext.convertDateToLocalizedStringGoodAcu(
                        new Date(statusData.BalancingPowerFixedTime)
                      )
                    : ""}
                </Grid>
                <StatusButton
                  size="small"
                  disabled={
                    businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.IKIGAI_DEMAND
                      ? false
                      : true
                  }
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        POWER_FORECAST_PLAN_URL +
                        "?areaId=" +
                        statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.power_gen_and_sales_plan}
                </StatusButton>
              </Grid>

              {/*需要調達計画 タイトル*/}
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {
                  languageContext.words
                    .final_confirmation_time_demand_and_procurement_plan
                }
              </Grid>
              {/*需要調達 ステータス,ボタン*/}
              <Grid
                // container
                item
                xs={12}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusOcctoChip(
                  "需調",
                  statusData.BalancingDemandSendStatus,
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.BALANCING_DEMAND
                  )
                )}
                <Grid
                  item
                  fontWeight={"bold"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {statusData.BalancingDemandFixedTime !== null
                    ? languageContext.convertDateToLocalizedStringGoodAcu(
                        new Date(statusData.BalancingDemandFixedTime)
                      )
                    : ""}
                </Grid>
                <StatusButton
                  size="small"
                  disabled={
                    businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.IKIGAI_POWER
                      ? businessModelChecker(statusData.areaId) !==
                        BUSINESS_MODEL.MARKET_TRAN
                        ? false
                        : true
                      : true
                  }
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." +
                        DEMAND_FORECAST_PLAN_URL +
                        "?areaId=" +
                        statusData.areaId
                    );
                  }}
                >
                  {languageContext.words.demand_and_procurement_plan}
                </StatusButton>
              </Grid>
              {/*部分供給 タイトル*/}
              <Grid
                item
                xs={12}
                fontSize="12px"
                color={"gray"}
                sx={{ mt: "10px" }}
              >
                {
                  languageContext.words
                    .final_confirmation_time_partial_supply_plan
                }
              </Grid>
              {/*部分供給 ステータス,ボタン*/}
              <Grid
                // container
                item
                xs={12}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {showStatusOcctoChip(
                  "部分",
                  statusData.BalancingPartsSendStatus,
                  businessModelChecker(statusData.areaId),
                  changeTheColorOfPaperTitle(
                    businessModelChecker(statusData.areaId),
                    PAPER_KIND.BALANCING_PARTS
                  )
                )}
                <Grid
                  item
                  fontWeight={"bold"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {statusData.BalancingPartsFixedTime !== null
                    ? languageContext.convertDateToLocalizedStringGoodAcu(
                        new Date(statusData.BalancingPartsFixedTime)
                      )
                    : ""}
                </Grid>
                <StatusButton
                  size="small"
                  disabled={
                    businessModelChecker(statusData.areaId) !==
                    BUSINESS_MODEL.IKIGAI_POWER
                      ? businessModelChecker(statusData.areaId) !==
                        BUSINESS_MODEL.MARKET_TRAN
                        ? false
                        : true
                      : true
                  }
                  onClick={() => {
                    areaContext.setAreaId(statusData.areaId);
                    handleClickLink(
                      ".." + BALANCING_PLAN_URL + "?areaId=" + statusData.areaId
                    )}
                  }
                >
                  {languageContext.words.simultaneous_commensurate_plan}
                </StatusButton>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      );
    }

    return (
      <Card variant="outlined" sx={{ maxWidth: "100%", m: "5px" }}>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {multiAreaIconColor()}
              {multiPowerForecastCard()}
              {multiDemandForecastCard()}
              {multiSpotBiddingCard()}
              {multiOneHourBiddingCard()}
              {multiBalancingPlanCard(statusData)}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    );
  };

  /**
   * ステータス一覧基盤コンポーネント
   */
  return (
    <div
      className="cn-main-display"
      style={{
        width: "calc(100% - 24px - 24px)",
        backgroundColor: "transparent",
        padding: "24px 0px",
      }}
    >
      <Stack>
        <GlobalSkeletonDatePicker />
      </Stack>
      <Stack sx={{ flexDirection: "row", mt: "30px" }}>
        <Box
          sx={{
            width: "calc((100% / 3) - 24px)",
            mt: "45px",
            mb: "45px",
          }}
        >
          <Box sx={{ width: "100%", margin: "auto" }}>
            {JapanMap(
              area01,
              area02,
              area03,
              area04,
              area05,
              area06,
              area07,
              area08,
              area09,
              area10
            )}
          </Box>
          <Box sx={{ width: "83%", margin: "auto" }}>
            {spotCircularDisplayJudge(businessModel) === false ? (
              <CircularStatus
                closingSystem={words.spot_bidding_deadline_time}
                closingSystemTime={limitJepx}
                bidAreaNum={areaSpotFixedCount}
                unBidAreaNum={areaCount - areaSpotFixedCount}
                limitTime={spotDiffTime}
                AreaFixStr={words.bidded_area}
                AreaUnFixStr={words.unbid_area}
              />
            ) : (
              <></>
            )}

            <CircularStatus
              closingSystem={words.balancingplan_submitted_on_the_day}
              closingSystemTime={limitOccto}
              bidAreaNum={areaOcctoFixedCount}
              unBidAreaNum={areaCount - areaOcctoFixedCount}
              limitTime={balancingDiffTime}
              AreaFixStr={words.submitted_area}
              AreaUnFixStr={words.not_submitted_area}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - (100% / 3) + 24px)",
            height: "920px",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "0px",
            },
          }}
        >
          {1 < dispStatusData.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                }}
              >
                {dispStatusData.map((statusData: StatusList.statusListInfo) => (
                  <MultiPlanStatus key={statusData.areaId} {...statusData} />
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "48px 0px",
                width: "100%",
              }}
            >
              {dispStatusData.map((statusData: StatusList.statusListInfo) => (
                <SinglePlanStatus key={statusData.areaId} {...statusData} />
              ))}
            </Box>
          )}
        </Box>
      </Stack>
      <CustomDialog
        title={words.status_list}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={() => {
          //
        }}
        onClose={() => setDigOpen(false)}
      />
      <CustomModalProgress open={!isLoaded} />
    </div>
  );
};

/**
 * インジケータ（スポット入札締切日時/同時同量計画提出日時）
 * @param props
 * @returns
 */
const CircularStatus = (props: circularStatusInfo) => {
  const {
    closingSystem,
    closingSystemTime,
    bidAreaNum,
    unBidAreaNum,
    limitTime,
    AreaFixStr,
    AreaUnFixStr,
  } = props;

  const languageContext = useContext(LanguageContext);
  const [percentage, setPercentage] = useState<number>(0);
  const [circleColor, setCircleColor] = useState<string>("rgb(33, 150, 243)");
  const [circleBgColor, setCircleBgColor] =
    useState<string>("rgb(187, 222, 251)");
  const [limitColor, setLimitColor] = useState<string>("");
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [fixMsg, setFixMsg] = useState<string>("");

  //propsが変更された際に表示情報を更新する。
  useEffect(() => {
    updatecircularStatusInfo();
  }, [closingSystemTime, bidAreaNum, unBidAreaNum, limitTime]);

  const updatecircularStatusInfo = () => {
    let tempPer = 0;
    let fixMessage = "";
    let isFix = false;
    let tempLimitColor = "";
    let tempColor = "";
    let tempBgColor = "";

    if (0 === unBidAreaNum) {
      tempPer = 100;
      fixMessage = languageContext.words.confirmed;
      tempColor = "#616161";
      tempBgColor = "#aaa";
      tempLimitColor = "#616161";
      isFix = true;
    }
    if (limitTime.hours <= 0 && limitTime.minutes <= 0) {
      //グレー
      tempPer = 100;
      fixMessage = languageContext.words.expiration_of_a_term;
      tempColor = "#616161";
      tempBgColor = "#aaa";
      tempLimitColor = "#616161";
      isFix = true;
    } else {
      if (
        //赤(15分前)
        limitTime.hours < 1 &&
        limitTime.minutes <= 15 &&
        0 <= limitTime.minutes
      ) {
        tempPer = 75;
        tempColor = "#d32f2f";
        tempBgColor = "#ffcdd2";
        tempLimitColor = "#d32f2f";
      } else if (
        //黄色
        limitTime.hours < 1 &&
        limitTime.minutes <= 30 &&
        15 < limitTime.minutes
      ) {
        tempPer = 50;
        tempColor = "#ff9800";
        tempBgColor = "#ffe0b2";
        tempLimitColor = "#ff9800";
      } else if (
        (limitTime.hours <= 1 && limitTime.minutes <= 30) ||
        (limitTime.hours <= 0 && 30 < limitTime.minutes)
      ) {
        //青
        tempPer = 50;
        tempColor = "#1976d2";
        tempBgColor = "#bbdefb";
        tempLimitColor = "#";
      } else {
        //青
        tempPer = 25;
        tempColor = "#1976d2";
        tempBgColor = "#bbdefb";
        tempLimitColor = "";
      }
      fixMessage =
        limitTime.hours.toString().padStart(2, "0") +
        ":" +
        limitTime.minutes.toString().padStart(2, "0");
      isFix = false;
    }
    setPercentage(tempPer);
    setCircleColor(tempColor);
    setCircleBgColor(tempBgColor);
    setLimitColor(tempLimitColor);
    setFixMsg(fixMessage);
    setIsFixed(isFix);
  };

  return (
    <Grid container sx={{ margin: "24px" }}>
      <Grid item xs={7}>
        <Grid container sx={{ padding: "12px 0px 20px 12px" }}>
          <Grid
            item
            xs={12}
            fontSize="12px"
            color={"gray"}
            fontWeight={"bold"}
            sx={{ mt: "10px" }}
          >
            {closingSystem}
          </Grid>
          <Grid item xs={12} fontSize="18px" fontWeight={"bold"}>
            {languageContext.convertDateToLocalizedStringGoodAcu(
              closingSystemTime
            )}
          </Grid>
          <Grid item xs={12} sx={{ fontWeight: "bold" }}>
            <span style={{ color: "gray", fontSize: "12px" }}>
              {AreaFixStr}
            </span>{" "}
            {bidAreaNum}
            {"　"}
            <span style={{ color: "gray", fontSize: "12px" }}>
              {AreaUnFixStr}
            </span>{" "}
            {unBidAreaNum}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} style={{ height: "100%" }}>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
          }}
        >
          <CircularProgress
            className="frontCircular"
            variant="determinate"
            size={130}
            value={percentage}
            thickness={4}
            sx={{
              zIndex: 2,
              position: "relative",
            }}
            style={{ color: circleColor }}
          />
          <CircularProgress
            className="backCircular"
            variant="determinate"
            size={130}
            value={100}
            thickness={4}
            sx={{
              zIndex: 1,
              position: "absolute",
            }}
            style={{ color: circleBgColor }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "16px",
            }}
          >
            {isFixed ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  fontSize="16px"
                  fontWeight={"bold"}
                  sx={{
                    textAlign: "center",
                    color: "rgb(33, 150, 243)",
                  }}
                >
                  {fixMsg}
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  fontSize="12px"
                  color={"gray"}
                  sx={{ textAlign: "center" }}
                >
                  {languageContext.words.to_be_confirmed}
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontSize="14px"
                  fontWeight={"bold"}
                  color={limitColor}
                  sx={{ textAlign: "center" }}
                >
                  {fixMsg}
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

/**
 * スポット入札のインジケータ表示非表示を返す
 * @param businessModel
 * @returns
 */
function spotCircularDisplayJudge(businessModel: businessModelList[]) {
  let isInAreaSelfConsignment = false;

  const tenantOfBusinesModel = tenantOfBusinessModel(businessModel);

  if (
    tenantOfBusinesModel.ikinaiFlag === true &&
    tenantOfBusinesModel.ikigaiPowerFlag === false &&
    tenantOfBusinesModel.ikigaiDemandFlag === false &&
    tenantOfBusinesModel.marketFlag === false &&
    tenantOfBusinesModel.ikinaiAndIkigaiPowerFlag === false &&
    tenantOfBusinesModel.ikinaiAndIkigaiDemandFlag === false &&
    tenantOfBusinesModel.ikinaiAndMarketFlag === false
  ) {
    isInAreaSelfConsignment = true;
  }
  return isInAreaSelfConsignment;
}

/**
 * ステータス一覧表示領域のグレーアウトを判定する
 * @param businessModel
 */
function changeTheColorOfPaper(
  businessModel: string,
  paperKind: string,
  statusData: StatusList.statusListInfo,
  PaletteMode: any
) {
  //
  let color = "";

  switch (paperKind) {
    case PAPER_KIND.POWER_FORECAST: {
      //
      if (businessModel === BUSINESS_MODEL.IKIGAI_DEMAND) {
        // グレーアウト
        PaletteMode === "dark" ? (color = "#5A5A5A") : (color = "#C8C8C8");
      } else {
        statusData.powerFixedTime !== null
          ? (color = "#00000000")
          : PaletteMode === "dark"
          ? (color = "#3E2F2F")
          : (color = "#D32F2F0A");
      }
      break;
    }
    case PAPER_KIND.DEMAND_FORECAST: {
      if (
        businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
        businessModel === BUSINESS_MODEL.MARKET_TRAN
      ) {
        // グレーアウト
        PaletteMode === "dark" ? (color = "#5A5A5A") : (color = "#C8C8C8");
      } else {
        statusData.demandFixedTime !== null
          ? (color = "#00000000")
          : PaletteMode === "dark"
          ? (color = "#3E2F2F")
          : (color = "#D32F2F0A");
      }
      break;
    }
    case PAPER_KIND.SPOT_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        // グレーアウト
        PaletteMode === "dark" ? (color = "#5A5A5A") : (color = "#C8C8C8");
      } else {
        statusData.spotFixedTime !== null
          ? (color = "#00000000")
          : PaletteMode === "dark"
          ? (color = "#3E2F2F")
          : (color = "#D32F2F0A");
      }
      break;
    }
    case PAPER_KIND.ONEHOUR_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        // グレーアウト
        PaletteMode === "dark" ? (color = "#5A5A5A") : (color = "#C8C8C8");
      } else {
        statusData.hourFixedTime !== null
          ? (color = "#00000000")
          : PaletteMode === "dark"
          ? (color = "#3E2F2F")
          : (color = "#D32F2F0A");
      }
      break;
    }
  }

  return color;
}

/**
 * ペーパー内のアイコンの色を返す
 * @param businessModel
 * @param paperKind
 * @returns
 */
function changeTheColorOfIcon(
  businessModel: string,
  paperKind: string,
  statusData: StatusList.statusListInfo
) {
  let color: any = "";

  switch (paperKind) {
    case PAPER_KIND.POWER_FORECAST: {
      //
      if (businessModel === BUSINESS_MODEL.IKIGAI_DEMAND) {
        // グレーアウト
        color = "disabled";
      } else {
        statusData.powerFixedTime === null
          ? (color = "error")
          : (color = "primary");
      }
      break;
    }
    case PAPER_KIND.DEMAND_FORECAST: {
      if (
        businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
        businessModel === BUSINESS_MODEL.MARKET_TRAN
      ) {
        // グレーアウト
        color = "disabled";
      } else {
        statusData.demandFixedTime === null
          ? (color = "error")
          : (color = "primary");
      }
      break;
    }
    case PAPER_KIND.SPOT_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        // グレーアウト
        color = "disabled";
      } else {
        statusData.spotFixedTime === null
          ? (color = "error")
          : (color = "primary");
      }
      break;
    }
    case PAPER_KIND.ONEHOUR_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        // グレーアウト
        color = "disabled";
      } else {
        statusData.hourFixedTime === null
          ? (color = "error")
          : (color = "primary");
      }
      break;
    }
  }
  return color;
}

/**
 * ペーパー内ボタンの表示非表示を返す
 * @param businessModel
 * @param paperKind
 * @returns
 */
function changeTheDisabledOfButton(businessModel: string, paperKind: string) {
  let disabled = false;

  switch (paperKind) {
    case PAPER_KIND.POWER_FORECAST: {
      if (businessModel === BUSINESS_MODEL.IKIGAI_DEMAND) {
        disabled = true;
      }
      break;
    }
    case PAPER_KIND.DEMAND_FORECAST: {
      if (
        businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
        businessModel === BUSINESS_MODEL.MARKET_TRAN
      ) {
        disabled = true;
      }
      break;
    }
    case PAPER_KIND.SPOT_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        disabled = true;
      }
      break;
    }
    case PAPER_KIND.ONEHOUR_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        disabled = true;
      }
      break;
    }
  }
  return disabled;
}

// 非表示時の色を返却
function changeTheColorOfPaperTitle(businessModel: string, paperKind: string) {
  //
  let color = "";

  switch (paperKind) {
    case PAPER_KIND.POWER_FORECAST: {
      if (businessModel === BUSINESS_MODEL.IKIGAI_DEMAND) {
        color = "grey";
      }
      break;
    }
    case PAPER_KIND.DEMAND_FORECAST: {
      if (
        businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
        businessModel === BUSINESS_MODEL.MARKET_TRAN
      ) {
        color = "grey";
      }
      break;
    }
    case PAPER_KIND.SPOT_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        color = "grey";
      }
      break;
    }
    case PAPER_KIND.ONEHOUR_BIDDING: {
      if (businessModel === BUSINESS_MODEL.IKINAI) {
        color = "grey";
      }
      break;
    }
    case PAPER_KIND.BALANCING_POWER: {
      if (businessModel === BUSINESS_MODEL.IKIGAI_DEMAND) {
        color = "grey";
      }
      break;
    }
    case PAPER_KIND.BALANCING_DEMAND: {
      if (
        businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
        businessModel === BUSINESS_MODEL.MARKET_TRAN
      ) {
        color = "grey";
      }
      break;
    }
    case PAPER_KIND.BALANCING_PARTS: {
      if (
        businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
        businessModel === BUSINESS_MODEL.MARKET_TRAN
      ) {
        color = "grey";
      }
      break;
    }
  }
  return color;
}

export default PlanStatusList;
