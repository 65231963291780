//このファイルにひたすら翻訳用連想配列を書いていく

//typeも作っておく
export type language = {
  [key: string]: string;
};

//英語版の文字がたくさん入っている連想配列
export const EnStrings: language = {
  bp_notification_title: `Notification of non-integration reflects`,
  bp_status_planning: `planning`,
  bp_status_submitting: `submitting`,
  bp_status_submitted: `submitted`,
  bp_status_recieved: `recieved`,
  bp_report_pgsp_next: `(Today/next day)Power generation and sales plan`,
  bp_report_dpp_next: `(Today/next day)Demand and procurement plan`,
  bp_report_pgsp_notice: `Power generation and sales plan inconsistency notification`,
  bp_report_dpp_notice: `Demand and procurement plan inconsistency notification`,
  bp_report_partial: `(Today/next day)Partial supply plan`,
  bp_notice_reflect: `Reflect`,
  last_update_info: `save date (last update user)`,
  energy_thermal: `Thermal power`,
  energy_hydro: `Hydroelectric power`,
  energy_solar: `Solar power`,
  energy_small_hydro: `Small hydroelectric power`,
  energy_wind: `Wind power`,
  energy_binary: `Geothermal power`,
  energy_biomass: `Biomass power`,
  note_pass_length_limit: `Passwords must be at least 12 and no more than 16 characters in length and a combination of numbers and upper and lower case alphabetic characters.`,
  example_one_hour: `input_legend [Published: ○] [Accepting: ◎] [Termination of transaction: ×]`,
  jepx_additional_user_spot_only_when_option_is_purchased: `[JEPX Additional user] Spot-only (when option is purchased)`,
  jepx_representative_user_for_spot_and_1_hour_trading: `[JEPX Representative User] For spot and 1 hour trading`,
  example_spot: `bidding_volume_legend [Sales (Sell): negative number] [Procurement (buy): positive number]`,
  filter_by_user_id_name: `Filter by user ID/user name`,
  area_status_selected_num: `[Area contract status of the selected application number]`,
  congestion_notification: `(Spot, 1 hour before) Congestion handling notification`,
  final_confirmation_time_demand_and_procurement_plan: `Date and time of final transmission and confirmation of demand and procurement plan`,
  final_confirmation_time_power_gen_and_sales_plan: `Date and time of final transmission and confirmation of power generation and sales plan`,
  final_confirmation_time_partial_supply_plan: `Date and time of final transmission and confirmation of partial supply plan`,
  contract_id_2_for_power_gen_and_sales_plan: `Contract ID 2`,
  cross_cut_partial_supply_load_following_supply: `Cross-cut partial supply (Load-following supply)`,
  abcdefg_mega_solar_power_plant: `ABCDEFG Mega Solar Power Plant`,
  power_plant_power_plant_power_plant_power_plant_power_plant: `Power Plant Power Plant Power Plant Power Plant Power Plant`,
  one_hour_external_other_bg_plus_exchanges: `1 hour (External other BG + Exchanges)`,
  spot_external_other_bg_plus_exchanges: `Spot (External other BG + Exchanges)`,
  priority_and_prorata_48_frame_setting: `Priority and prorata 48-frame setting`,
  plan_submitter_codeprovider_code: `Plan submitter code/Provider code`,
  spot_contract_price: `Spot contract price (Yen/kWh)`,
  connection_and_transfer_supply_agreement_listsetting: `Connection and transfer supply agreement list/setting`,
  gen_contractors_and_procurement_sources_listsetting: `Generation contractors and procurement sources list/setting`,
  notified_partial_supply_base_supply: `Notified partial supply (Base supply)`,
  abcdefg_tokyo_factory_inc: `ABCDEFG Tokyo factory, Inc.`,
  new_gen_contractors_and_procurement_sources_registration: `New generation contractors and procurement sources registration`,
  block_bids_executed_planned_num: `Number of block bids executed/planned`,
  termination_of_transaction: `[Termination of transaction: ×]`,
  average_contract_price: `Average contract price (Yen/kWh)`,
  latest_contract_price: `Latest contract price (yen/kWh)`,
  new_password_confirmation: `New password (for confirmation)`,
  contract_id_to_following_load: `Contract ID to following the load`,
  base_contract_id: `Contract ID to base`,
  master_maintenance_operation_setting: `Master maintenance and operation setting`,
  character_for_checking_planned_values: `Generation characteristics for checking planned values`,
  contracts_bids_num_before_gc: `Number of contracts/bids (Before GC)`,
  sell_contracting_volume_mwhh: `Sell - Contracting volume (MWh/h)`,
  buy_contracting_volume_mwhh: `Buy - Contracting volume (MWh/h)`,
  contracts_bids_num_after_gc: `Number of contracts/bids (GC)`,
  constant_backup_only: `(Constant backup only)`,
  sell_bidding_volume: `Sell - Bidding volume (MWh/h)`,
  buy_bidding_volume: `Buy - Bidding volume (MWh/h)`,
  sell_lowest_price: `Sell - Lowest price (Yen/kWh)`,
  buy_lowest_price: `Buy - Lowest price (Yen/kWh)`,
  certificate_general_transmission_distribution: `Certificate for General power transmission and distribution`,
  connection_and_transfer_supply_agreement_editting: `Connection and transfer supply agreement editting`,
  gen_contractors_and_procurement_sources_editting: `Generation contractors and procurement sources editting`,
  the_next_day_partial_supply_notification_value: `(The next day) Partial supply notification value`,
  the_day_partial_supply_notification_value: `(The day) Partial supply notification value`,
  other_demand_bg_retail_power_receiving: `Other demand BG (Retail power receiving)`,
  xxxx_partial_supply_consumer: `XXXX partial supply consumer`,
  market_status_entire_area: `Market status (Entire Area)`,
  entire_area: `Entire area`,
  partial_supply_note_val: `(Monthly) Partial supply notification value`,
  procurement_buy_value: `[Procurement (buy): +value]`,
  procurement_plan_mwhh: `Procurement plan (MWh/h)`,
  contract_price_yenkwh: `Contract price (Yen/kWh)`,
  num_of_normal_bid: `Number of normal bids executed/planned`,
  keep_me_logged_in: `Keep me logged in`,
  sales_sell_value: `[Sales (Sell): -Value]`,
  bid_price_yenkwh: `Bid price (Yen/kWh)`,
  sales_plan_mwhh: `Sales plan (MWh/h)`,
  plant_name_for_note_form: `Power plant name for notification form`,
  jepx_representative_user: `[JEPX Representative User]`,
  jepx_additional_user: `[JEPX Additional user]`,
  procurement_src_list_setting: `Procurement sources breakdown list/setting`,
  the_next_day_demand_and_procurement_plan: `(The next day) Demand and procurement plan`,
  sales_destinations_list_setting: `Sales destinations breakdown list/setting`,
  the_next_day_power_gen_and_sales_plan: `(The next day) Power generation and sales plan`,
  monthly_demand_and_procurement_plan: `(Monthly) Demand and procurement plan`,
  the_day_demand_and_procurement_plan: `(The day) Demand and procurement plan`,
  supplier_code_following_load: `Supplier code to following the load`,
  base_supplier_code: `Supplier code to base`,
  weekly_demand_and_procurement_plan: `(Weekly) Demand and procurement plan`,
  annual_demand_and_procurement_plan: `(Annual) Demand and procurement plan`,
  monthly_power_gen_and_sales_plan: `(Monthly) Power generation and sales plan`,
  the_day_power_gen_and_sales_plan: `(The day) Power generation and sales plan`,
  weekly_power_gen_and_sales_plan: `(Weekly) Power generation and sales plan`,
  annual_power_gen_and_sales_plan: `(Annual) Power generation and sales plan`,
  demander_name_etc: `Demander name, business name, etc`,
  supplier_to_following_the_load: `Supplier to following the load`,
  base_supplier: `Supplier to base`,
  for_power_gen_and_sales_planB: `(For power generation and sales plan)`,
  transmission_loss_ratio_info: `Area transmission loss ratio information`,
  power_gen_imbalance_monitor: `Power generation imbalance monitoring`,
  nine_nine_nine_nine_yenkwh: `99.99 yen/kWh`,
  filter_by_power_plant_name: `Filter by demander name`,
  demand_imbalance_monitor: `Demand imbalance monitoring`,
  reflects_congestion_note: `Reflects planning for congestion processing notifications`,
  contract_quantity_mwhh: `Contract volume (MWh/h)`,
  planned_daily_demand_kw: `Planned daily demand (kW)`,
  bidding_volume_mwhh: `Bidding volume (MWh/h)`,
  service_result_note: `Service processing result notification`,
  blank_requirements: `(Blank if end date undecided)`,
  fit_administrator: `FIT administrator`,
  confirm_password: `Confirm password`,
  new_alarm_registration: `New Alarm Registration`,
  certificate_wide_area_agencies: `Certificate for Wide Area Agencies`,
  spot_bidding_deadline_time: `Spot Bidding Deadline time`,
  balancingplan_submitted_on_the_day: `Simultaneous commensurate plan submitted on the day`,
  demandMonitoring_title: `Demand imbalance monitoring`,
  powergenerationmonitoring_title: `Power generation imbalance monitoring`,
  power_gen_imbalance_monitor_menu: `Power generation imbalance monitoring`,
  notified_partial_supply_setting: `Notified partial supply setting`,
  one_hour_and_30_minutes_before_actual_supply_and_demand: `1 hour and 30 minutes before actual supply and demand`,
  new_procurement_sources_breakdown_registration: `New procurement sources breakdown registration`,
  new_sales_destinations_breakdown_registration: `New sales destinations breakdown registration`,
  sales_destinations_name_breakdown: `Sales destinations name (breakdown)`,
  note_type_partial_supply_setting: `Notification type partial supply setting`,
  specific_supply_and_demand_001: `Specific supply and demand 001`,
  area_by_area_plan_submitter: `Area-by-area plan submitter`,
  power_gen_bg_listsetting: `Power generation BG list/setting`,
  filter_by_demander_name: `Filter by demander name`,
  gen_contractor_operator: `Generation contractor/Operator`,
  plan_submitterprovider: `Plan submitter/Provider`,
  demand_bg_listsetting: `Demand BG list/setting`,
  contracted_power_mw: `Contracted power (MW)`,
  client_certificate: `Client certificate`,
  retailer_id_number: `Retailer Identification Number`,
  contracted_energy: `Contracted energy (kWh)`,
  alarm_listsetting: `Alarm list`,
  jepx_participant: `JEPX Participant`,
  precipitation_mmh: `Precipitation (mm/h)`,
  price_yenkwh: `Price (Yen/kWh)`,
  sell_mwhh: `Sell (MWh/h)`,
  buy_mwhh: `Buy (MWh/h)`,
  alarm_setting: `Alarm setting`,
  jepx_user_registration: `JEPX user registration`,
  new_user_registration: `New User Registration`,
  supply_contract_setting_setting: ` Supply contract setting setting `,
  area_transmission_loss_info: `Area transmission loss information`,
  sales_destinations_group_list: `Sales destinations group list`,
  sales_destinations_group_breakdown: `Sales destinations group breakdown`,
  reflection_time_of_contract_plan: `Date and time of reflection of contract plan`,
  new_supply_contract_registration: `New supply contract registration`,
  resume_send_response_acquisition: `Resume send response acquisition`,
  sales_destinations_listsetting: `Sales destinations list/setting`,
  monitoring_threshold_weekdays: `Monitoring threshold (Weekdays)`,
  power_gen_and_sales_plan_type: `Power generation and sales plan type`,
  new_power_gen_bg_registration: `New power generation BG registration`,
  monitoring_threshold_holiday: `Monitoring threshold (Holiday)`,
  plan_value_cooperation_code: `Plan value cooperation code`,
  one_hour_ago_bidding_limit: `Intraday bidding limit`,
  new_demand_bg_registration: `New demand BG registration`,
  multi_area_administrator: `Multi-area administrator`,
  system_constant_setting: `System constant setting`,
  power_plant_list_setting: `Power plant list/setting`,
  internal_other_bg_001B: `Internal other BG 001`,
  supply_point_id_number: `Supply Point Identification Number`,
  event_note_on_or_off: `Event notification availability`,
  created_last_updated: `Created Last Updated`,
  demander_list_setting: `Demander list/setting`,
  plan_submitter_code: `Plan submitter code`,
  contracted_power_kw: `Contracted power (kW)`,
  block_bidding_limit: `Block bidding limit`,
  spot_bidding_limit: `Spot bidding limit`,
  maximum_output_kw: `Maximum output (kW)`,
  pannel_type: `Pannel installation type`,
  temperature_coe: `Tempreture coefficient`,
  ac_output: `AC output (kW)`,
  panel_tilt: `Pannel tilt`,
  panel_azimuth: `Azimuth`,
  panel_coe: `Coefficient`,
  panel_power_decline: `Power decline`,
  rated_output: `Rated output (kW)`,
  sun_pannel: `Sun light pannel`,
  weekly_plan_check: `Weekly plan check`,
  power_gen_energy: `Power generation energy (kWh)`,
  user_list_setting: `User list/setting`,
  demand_kwh: `Demand (kWh)`,
  power_gen_contractor_code: `Power generation contractor code`,
  new_connection_info_registration: `New connection information registration`,
  voltage_class_assigned_num: `Voltage class assigned number`,
  sales_dest_breakdown_setting: `Sales destinations breakdown setting`,
  demand_monitoring_menu: `Demand imbalance monitoring`,
  new_password: `New password`,
  interconnection_supply_agreement_list: `Interconnection supply agreement list`,
  download_form: `Download  form`,
  procurement_src_editting: `Procurement sources breakdown editting`,
  sales_destinationseditting: `Sales destinations breakdown editting`,
  new_sales_destinations_registration: `New sales destinations registration`,
  upper_upper_and_lower_lower_limits: `Upper-upper and lower-lower limits`,
  sales_destination_name: `Sales destinations breakdown name`,
  procurement_src_name: `Procurement sources breakdown name`,
  new_registration: `New Registration`,
  new_power_plant_registration: `New power plant registration`,
  power_trading_combination: `Power trading combination`,
  power_src_specific_code: `Power source specific code`,
  extra_high_voltage_changed: `Extra-high-voltage (Changed)`,
  automatic_setting_enabled: `Automatic setting enabled`,
  new_demander_registration: `New demander registration`,
  internal_other_bg_001_A: `Internal other BG 001`,
  auto_bid_deletion_time: `Automatic bid deletion`,
  contract_operator_type: `Contract operator type`,
  bid_automatic_setting: `Bid automatic setting`,
  automatic_delete_deadline_setting: `Bidding deadline and automatic deletion settings`,
  auto_bid_deletion_off: `Automatic bid deletion disabled`,
  todays_power_gen_plan: `Today's power generation plan`,
  expiration_start_date: `Expiration start date`,
  auto_bid_deletion_on: `Automatic bid deletion enabled`,
  codes_in_parentheses: `Codes in parentheses`,
  contract_id_number_1: `Contract identification number 1`,
  contract_id_number_2: `Contract identification number 2`,
  high_voltage_changed: `High-voltage (Changed)`,
  system_administrator: `System administrator`,
  gen_contractors_name: `Generation contractors name`,
  low_voltage_changed: `Low-voltage (Changed)`,
  expiration_end_date: `Expiration end date`,
  todays_demand_plan: `Today's demand plan`,
  operation_message: `Operation message`,
  power_gen_bg_code: `Power generation BG code`,
  all_power_gen_bg: `Power generation BG Unselected`,
  plan_change_code: `Plan change code`,
  power_gen_bg_001: `Power generation BG 001`,
  exchanges_tokyo: `Exchanges (Tokyo)`,
  demand_bg_code: `Demand BG code`,
  all_demand_bg: `Demand BG Unselected`,
  demand_bg_001: `Demand BG 001`,
  daily_amount_kwh: `Daily amount (kWh)`,
  status_list: `Status list`,
  wind_speed: `Wind speed (m/s)`,
  published: `[Published: ○]`,
  accepting: `[Accepting: ◎]`,
  mail_address: `Mail Address`,
  generation_bg_setting: `Generation BG setting`,
  power_bg_setting: `Power BG setting`,
  effective_date_start_date: `Effective Date Start Date`,
  expiration_date: `Expiration Date`,
  demand_bg_setting: `Demand BG setting`,
  new_contractor_registration: `New Contractor Registration`,
  generation_contractors_setting: `Generation contractors setting`,
  contract_operator_Id: `Contract operator Identification`,
  bidded_area: `Bidded Area`,
  auto_bidding_off: `Auto Bidding off`,
  demandforecastplan: `Demand Forecast Plan`,
  powerforecastplan: `Power Forecast Plan`,
  account_setting: `Account setting`,
  simultaneous_commensurate_plan: `Simultaneous commensurate plan`,
  in_house_managed_power_supply: `In-house-managed power supply`,
  demand_and_procurement_plan: `Demand and procurement plan`,
  power_gen_threshold_setting: `Power generation threshold setting`,
  other_company_power_supply: `Other company-managed power supply`,
  balance_supply_and_demand: `Balance of supply and demand`,
  internal_self_consignment: `Internal self-consignment`,
  external_self_consignment: `External self-consignment`,
  application_number_title: `[Application number]`,
  power_gen_and_sales_plan: `Power generation and sales plan`,
  note_result_acquisition: `Notification result acquisition`,
  self_consignment_tokyo: `Self-consignment - tokyo`,
  self_consignment_total: `Self-consignment - total`,
  total_procurement_plan: `Total procurement plan`,
  details_of_bid_status: `Details of bid status`,
  bidding_deadline_time: `Bidding Deadline time`,
  contracted_power_unit: `Contracted power unit`,
  facility_usage_period: `Facility usage period`,
  power_gen_bg_editting: `Power generation BG editting`,
  list_of_demand_plans: `List of demand plans`,
  power_gen_plans_list: `List of power generation plans`,
  one_hour_ago_bidding: `Intraday bidding`,
  affiliation_power_bg: `Affiliation power generation BG`,
  affiliation_demand_bg: `Affiliation demand BG`,
  partial_supply_form: `Partial supply form`,
  system_forecasting: `System forecasting`,
  demand_bg_editting: `Demand BG editting`,
  balance_monitoring: `Balance monitoring`,
  notification_list: `Notification list`,
  power_gen_bg_name: `Power generation BG name`,
  login_information: `Login information`,
  all_power_plants: `Power plants Unselected`,
  total_sales_plan: `Total sales plan`,
  pattern_setting: `Pattern setting`,
  alarm_editting: `Alarm editting`,
  repeat_setting: `Repeat setting`,
  demand_bg_name: `Demand BG name`,
  block_bidding: `Block bidding`,
  supplier_area: `Supplier area`,
  spot_bidding: `Spot bidding`,
  alarm_status: `Alarm status`,
  deviation_mw: `Deviation (MW)`,
  user_editing: `User editing`,
  sender_code: `Sender code`,
  bp_name: `Bussiness person Name`,
  agency_code: `Agency code`,
  client_code: `Client code`,
  all_demand: `Demander Unselected`,
  alarm_name: `Alarm name`,
  login_name: `Login name`,
  area_unselected: `Area Unselected`,
  all_rolls: `All rolls`,
  user_name_nobashi: `User name`,
  download: `Download`,
  tenant_id: `Tenant ID`,
  user_id: `User ID`,
  upload: `Upload`,
  transmission_loss_info: `transmission loss information`,
  interconnection_Supply_Agreement: `Interconnection Supply Agreement`,
  event_notification: `Event notification`,
  contract_id_number: `Contract identification number`,
  power_plant_setting: `Power plant setting`,
  partial_supply_presence_absence: `Partial supply presence or absence`,
  business_name_etc: `Business name, etc`,
  sales_destinations_setting: `Sales destinations setting`,
  unbid: `Unbid`,
  unbid_area: `Unbid Area`,
  submitted_area: `Submitted Area`,
  not_submitted_area: `Not Submitted Area`,
  date_and_time_reflection_contract: `Date and time of reflection of contract`,
  loss_ratio_switching: `Loss ratio switching:`,
  automatic_price_adjustment: `Automatic price adjustment`,
  automation_setting_enabled: `Automatic setting enabled`,
  demandforecast_list: `List of demand plans`,
  powerforecast_list: `List of power generation plans`,
  biddingScreen_title: `Spot bidding`,
  onehouradvancebidplan_title: `Intraday Bidding`,
  balancingplan: `Simultaneous commensurate plan`,
  output_report: `Report output`,
  balance_of_payments_report: `Balance of payments report`,
  notification_title: `Notification list`,
  planned_daily_demand: `Planned daily demand`,
  power_gen_bg_list: `Power generation BG list`,
  demand_gen_bg_list: `Demand BG list`,
  select_area: `Select Area`,
  intraregional_sales_destinations: `Intraregional sales destinations`,
  affiliated_sales_destinations: `Affiliated sales destinations`,
  non_adjustable_power_supply: `Non-Adjustable power supply`,
  sales_destinations_editting: `Sales destinations editting`,
  date_of_change_reflection: `Date of change reflection`,
  certificate_registration: `Certificate registration`,
  sales_destinations_type: `Sales destinations type`,
  sales_destinations_name: `Sales destinations name`,
  upper_and_lower_limits: `Upper and lower limits`,
  delivery_date_of_power: `Delivery date of power`,
  planned_demand_value: `Planned demand value`,
  power_gen_contractor: `Power generation contractor`,
  supplier_information: `Supplier information`,
  power_receiving_area: `Power receiving area`,
  procurement_src_type: `Procurement sources type`,
  power_plant_editting: `Power plant editting`,
  ambient_temperature: `Ambient temperature (℃)`,
  affiliated_proc_src: `Affiliated procurement sources`,
  display_start_date: `Display start date`,
  partial_contracted: `Partial contracted`,
  planned_gen_value: `Planned generation value`,
  sellable_quantity: `Sellable quantity`,
  internal_other_bg: `Internal other BG`,
  automatic_setting: `Automatic setting`,
  deviation_percent: `Deviation (%)`,
  supply_start_date: `Supply start date`,
  demander_editting: `Demander editting`,
  participant_name: `Participant name`,
  power_plant_name: `Power plant name`,
  power_plant_001: `Power plant 001`,
  storage_battery_ownership: `Battery ownership`,
  storage_battery_owned: `owned`,
  storage_battery_unowned: `unowned`,
  storage_battery_setting_button: `Storage Battery setting`,
  supply_end_date: `Supply end date`,
  contractor_type: `Contractor Type`,
  connection_area: `Connection area`,
  manual_bidding: `Manual bidding`,
  plan_reflected: `Plan reflected`,
  participant_id: `Participant ID`,
  power_producer: `Power producer`,
  biomass_power: `Biomass power`,
  contract_area: `Contract area`,
  demander_name: `Demander name`,
  auto_bidding: `Automatic bidding is in progress`,
  demander_01: `Demander 01`,
  start_time_: `Start time : `,
  grid_code: `Grid code`,
  all_types: `All types`,
  pattern_1: `Pattern 1`,
  guidance: `Guidance`,
  password: `Password`,
  yen_kwh: `Yen/kWh`,
  message: `Message`,
  status: `Status`,
  ok: `OK`,
  cancel: `Cancel`,
  all_bg: `All BG`,
  next_notification_date: `Next notification date`,
  user_name: `User Name`,
  areas_of_responsibility: `Areas of responsibility`,
  bg_code: `BG code`,
  contract_Type: `Contract Type`,
  powerforecast_energy: `PowerForecast energy`,
  demandforecast_energy: `DemandForecast energy`,
  loss_ratio_on: `Loss ratio ON`,
  loss_ratio_off: `Loss ratio OFF`,
  log_out: `Log out`,
  user_list: `User list`,
  power_plant_list: `Power plant list`,
  demander_list: `Demander list`,
  date_and_time_of_occurrence: `Date and time of occurrence`,
  power_gen_reserve_capacity: `Power generation reserve capacity`,
  other_company_procurement: `Other company procurement`,
  execute_bid_cancellation: `Execute bid cancellation`,
  shortage_of_procurement: `Shortage of procurement`,
  adjustment_amount_comma: `Adjustment amount:`,
  adjustable_power_supply: `Adjustable power supply`,
  decision_date_and_time: `Decision date and time`,
  contract_status_jokyo: `Contract status`,
  bidding_date_and_time: `Bidding date`,
  waiting_for_contract: `Waiting for contract`,
  application_number_B: `Application number`,
  bidding_time_jikoku: `Bidding time`,
  contract_unit_price: `Contract unit price`,
  weather_information: `Weather information`,
  in_house_management: `In-house management`,
  market_transactions: `Market transactions`,
  bidding_time_jikan: `Bidding time`,
  company_a_factory: `Company A factory`,
  additional_period: `Additional period`,
  threshold_setting: `Threshold setting`,
  power_supply_type: `Power supply type`,
  power_gen_results: `Power generation results`,
  reflection_status: `Reflection Status`,
  power_gen_method: `Power generation method`,
  procurement_plan: `Procurement plan`,
  bid_cancellation: `Bid cancellation`,
  bid_canceled: `Bid canceled`,
  adjustable_cycle: `Adjustable cycle`,
  contracted_power: `Contracted power`,
  waiting_for_send: `Waiting for send`,
  form_preparation: `Form preparation`,
  save_temporarily: `Save temporarily`,
  in_house_status: `In-house status`,
  waiting_for_bid: `Waiting for bid`,
  unsuccessful_bid: `Unsuccessful bid`,
  bulk_revocation: `Bulk revocation`,
  management_type: `Management type`,
  contract_status: `Contract Status`,
  power_gen_plan: `Power generation plan`,
  normal_bidding: `Normal bidding`,
  market_details: `Market details`,
  plan_confirmed: `Plan confirmed`,
  plan_submitted: `Plan submitted`,
  demand_results: `Demand results`,
  bidding_limit: `Bidding limit`,
  bid_execution: `Bid execution`,
  contract_name: `Contract name`,
  voltage_class: `Voltage class`,
  power_plant_a: `Power plant A`,
  past_results: `Past results`,
  power_gen_bg: `Power generation BG`,
  one_hour_ago: `Intraday`,
  input_legend: `Input Legend`,
  bulk_bidding: `Bulk bidding`,
  salebuy_type: `Sale/buy type`,
  notification: `Notification`,
  infomation: `Infomation`,
  demand_plan: `Demand plan`,
  update_date: `Update date`,
  postal_code: `Postal code`,
  sales_plan: `Sales plan`,
  bid_number: `Bid number`,
  start_time: `Adjustment start time`,
  prefecture: `Prefecture`,
  demand_bg: `Demand BG`,
  save_date: `Save date`,
  past_plan: `Past plan`,
  bid_price: `Bid price`,
  form_name: `Form name`,
  report_kind: `Report kind`,
  output: `Output`,
  displayed: `Displayed`,
  no_change: `No change`,
  change_no: `Change No.`,
  bid_type: `Sale/buy type`,
  priority: `Priority`,
  new_bid: `New Bid`,
  no_type: `No type`,
  prorata: `Prorata`,
  repeat: `Repeat`,
  change: `Change`,
  login: `Login`,
  spot: `Spot`,
  bg01: `BG01`,
  file: `File`,
  self_consignment: `Self consignment`,
  contrac_price: `Contract price`,
  latest_results: `Latest Results`,
  system: `System`,
  group: `Group`,
  auto_configuration: `Auto configuration`,
  bg_name: `BG Name`,
  to_be_confirmed: `To be confirmed`,
  expiration_of_a_term: `Expiration of a term`,
  demandforecast: `Demand plan`,
  powerforecast_title: `Power generation plan`,
  step: `Combination`,
  notification_setting: `Notification setting`,
  log_in: `Login`,
  forecast_list: `Forecast List`,
  powerforecast: `Power generation plan`,
  submitting_plan: `Plan submitted`,
  report: `Report`,
  excess_or_deficiency: `Excess or deficiency`,
  number_of_divisions: `Number of divisions`,
  status_jotai_colon: `Status : `,
  sales_destinations: `Sales destinations`,
  contract_quantity: `Contract volume`,
  adjustment_amount: `Adjustment amount`,
  hokkaido_region: `Hokkaido`,
  procurement_src: `Procurement sources`,
  not_implemented: `Not implemented`,
  before_bidding: `Before Bidding`,
  under_contract: `Under contract`,
  bidding_volume: `Bidding volume`,
  business_sites: `Business Sites`,
  supply_power: `Supply power`,
  unable_to_do: `Unable to do`,
  created_from: `Created from`,
  unregistered: `Unregistered`,
  power_plant: `Power plant`,
  solar_power: `Solar power`,
  implemented: `Implemented`,
  target_date: `Target Date`,
  reforecast: `Reforecast`,
  contracted: `Contracted`,
  thirty_min: `30 min.`,
  kagoshima: `Kagoshima`,
  set_value: `Set value`,
  exchanges: `Exchanges`,
  demander: `Demander`,
  received: `Received`,
  updater: `Updated by`,
  deleted: `Deleted`,
  changed: `Changed`,
  cycle: `Cycle : `,
  area: `Area`,
  sent: `Sent`,
  role: `Role`,
  plan_value: `Plan value`,
  results_jissekichi: `Results`,
  able: `able`,
  user: `User`,
  bidded: `Bidded`,
  submitted: `Submitted`,
  not_submitted: `Not Submitted`,
  deviation_ratio: `Deviation ratio`,
  upper_upper_limits: `Upper-upper limits`,
  lower_lower_limits: ` Lower-lower limits`,
  lowest_price_sell: `Lowest price`,
  highest_price_buy: `Highest price`,
  notification_date: `Notification date`,
  sales_plan_duplication: `Sales plan duplication`,
  procurement_plan_duplication: `Procurement plan duplication`,
  delete_request: `Delete request`,
  delete_failed: `Delete failed`,
  theme: `Theme`,
  light: `Light`,
  dark: `Dark`,
  hour_ago: `Intraday`,
  hydroelectric_power: `Hydroelectric power`,
  extra_high_voltage: `Extra-high-voltage`,
  geothermal_power: `Geothermal power`,
  hokuriku_region: `Hokuriku`,
  chugoku_region: `Chugoku`,
  shikoku_region: `Shikoku`,
  wind_direction: `Wind direction`,
  tohoku_region: `Tohoku`,
  kansai_region: `Kansai`,
  kyushu_region: `Kyushu`,
  tokyo_region: `Tokyo`,
  kinki_region: `Kinki`,
  chubu_region: `Chubu`,
  total_amount: `Total amount`,
  status_jokyo: `Status`,
  cancellation: `Bid cancellation`,
  active_alarm: `Active alarm`,
  high_voltage: `High-voltage`,
  status_jotai: `Status`,
  lower_limit: `Lower limit`,
  upper_limit: `Upper limit`,
  low_voltage: `Low-voltage`,
  wind_power: `Wind power`,
  monitoring: `Monitoring`,
  company_a: `Company A`,
  breakdown: `breakdown`,
  confirmed: `Confirmed`,
  annually: `Annually`,
  weather: `Weather`,
  receive: `Receive`,
  remarks: `Remarks`,
  monthly: `Monthly`,
  result: `Result`,
  delete: `Delete`,
  weekly: `Weekly`,
  today: `Today`,
  timeB: `Time`,
  timeC: `Time`,
  daily: `Daily`,
  save: `Save`,
  copy: `Copy`,
  reflect_contract_amount: `Reflect contract amount in plan`,
  unit: `Unit`,
  combination: `Combination`,
  part: `Part`,
  sell: `Sell`,
  bidding_time_zone: `Bidding time`,
  price_yen_kwh: `Price`,
  plan: `Plan`,
  unplanned: `Unplanned`,
  fixed: `Fixed`,
  type: `Type`,
  none: `None`,
  edit: `Edit`,
  buy: `Buy`,
  bidding: `Bid`,
  yes: `Yes`,
  no: `No`,
  off: `Off`,
  all: `All`,
  bg: `BG`,
  kw: `kW`,
  on: `On`,
  total: ` - total`,
  daily_amount: `Daily amount`,
  deviation: `Deviation `,
  results: `Results`,
  forecasting: `forecasting`,
  survice: `Survice`,
  surviceForce: `Supply power`,
  supply: `Supply`,
  sale: `Sale`,
  reserveForce: `Power generation reserve capacity`,
  list: `List`,
  registration: `Registration`,
  okinawa_region: `Okinawa`,
  latest: `Latest`,
  average: `Average`,
  legend: `Legend`,
  demand: `Demand`,
  notification_tuti: `Alarm`,
  setting: `Setting`,
  setting_title: `Setting`,
  change_simple: `PW Change`,
  forecast: `Plan`,
  subtotal: `Subtotal`,
  tekito: `.`,
  time: `Time`,
  fri: `Fri`,
  mon: `Mon`,
  wed: `Wed`,
  sat: `Sat`,
  sun: `Sun`,
  tue: `Tue`,
  min: `Min.`,
  thu: `Thu`,
  valid: `Valid`,
  invalid: `Invalid`,
  year_and_month: `Year and Month`,
  month: `Month`,
  end_of_month: `End of month`,
  day: `Day`,
  power_bp_name: `BP Name`,
  power_bp_code: `BP Code`,
  new_power_kind: `BP Kind`,
  target_area: ` Jurisdiction Area`,
  new_power_bp: `Submit Power BP`,
  selling_price: `Selling price`,
  buying_price: `Buying price`,
  buying_volume: `Buying volume`,
  selling_volume: `Selling volume`,
  or_more: `Over`,
  or_less: `Under`,
  modal_close: `Close`,
  intraday_auto_setting_1: `2 hour before actual supply and demand`,
  intraday_auto_setting_2: `3 hour before actual supply and demand`,
  intraday_auto_setting_3: `4 hour before actual supply and demand`,
  intraday_auto_setting_4: `5 hour before actual supply and demand`,
  intraday_auto_setting_5: `6 hour before actual supply and demand`,
  intraday_auto_setting_6: `7 hour before actual supply and demand`,
  intraday_auto_setting_7: `8 hour before actual supply and demand`,
  intraday_auto_setting_8: `9 hour before actual supply and demand`,
  intraday_auto_setting_9: `10 hour before actual supply and demand`,
  intraday_auto_setting_10: `11 hour before actual supply and demand`,
  intraday_auto_setting_11: `12 hour before actual supply and demand`,
  intraday_bid_failure: `Unsuccessful tenders`,
  intraday_auto_deletion_enabled: `Automatic bid cancellation enabled`,
  intraday_auto_deletion_unenabled: `Automatic bid cancellation unenabled`,
  intraday_auto_cycle_1: `10 min.`,
  intraday_auto_cycle_2: `20 min.`,
  intraday_auto_cycle_3: `30 min.`,
  intraday_auto_cycle_4: `40 min.`,
  intraday_auto_cycle_5: `50 min.`,
  intraday_auto_cycle_6: `60 min.`,
  extra_high_voltage_range_limit: `Extra-high-voltage: 0.1～6.0`,
  high_voltage_range_limit: `High-voltage: 1.0～8.0`,
  low_voltage_range_limit: `Low-voltage: 3.0～12.0`,
  setting_required: `*Required. `,
  setting_available_characters_1: `*Must be a minimum of 8 characters. Must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit and 1 of the following special characters, hyphen(-), underscore(_)`,
  setting_available_characters_2: `Must be entered using alphanumeric characters and the following special characters, at sign(@), hyphen(-), period(.), underscore(_)`,
  setting_available_characters_3: `Must be entered within 12 characters. Must contain uppercase and lowercase alphanumeric characters and be entered with different content than the user ID`,
  setting_available_characters_4: `Must be entered using only numbers`,
  setting_available_characters_5: `Must be entered in alphanumeric characters only`,
  setting_available_characters_6: `Must be entered in 4 alphanumeric digits`,
  setting_available_characters_7: `Must be entered in 5 alphanumeric digits`,
  setting_character_limit_1: `Must be entered within 16 characters`,
  role_all: `All roles`,
  role_system_administrator: `System administrator`,
  role_tenant_administrator: `Tenant administrator`,
  role_multi_area_administrator: `Multi-area administrator`,
  role_area_plan_submitter: `Area-by-area plan submitter`,
  role_power_plant: `Power plant`,
  role_demand: `Demander`,
  role_fit_administrator: `FIT administrator`,
  setting_power_supply_type_1: `Unadjusted`,
  setting_power_supply_type_2: `Adjustment`,
  setting_management_type_1: `Own company`,
  setting_management_type_2: `Other power plant`,
  setting_management_type_3: `Other retail`,
  setting_management_type_4: `JBU`,
  setting_unselected: `Unselected`,
  setting_bp_name: `Business person`,
  setting_bp_code: `Business person code`,
  power_contractor: `Power generation contractor`,
  power_contractor_common: `Common`,
  power_contractor_other_companies_procurement: `Other companies procurement`,
  power_receive_point_number: `Power receiving point ID`,
  setting_latitude: `Latitude`,
  setting_longitude: `Longitude`,
  panel_mounted_type_1: `Roof-mounted`,
  panel_mounted_type_2: `Roof-integrated`,
  panel_mounted_type_3: `Trestle-mounted (backside release)`,
  panel_mounted_type_4: `Trestle-mounted (sealed on backside)`,
  panel_type_1: `Crystal`,
  panel_type_2: `CIS`,
  panel_type_3: `Amorphous`,
  panel_type_4: `HIT`,
  storage_battery_setting_title: `Storage battery setting`,
  storage_battery_setting_id: `Battery ID`,
  storage_battery_setting_lower: `Battery lower limit(%)`,
  storage_battery_setting_upper: `Battery upper limit(%)`,
  storage_battery_setting_capacity: `Battery capacity(kWh)`,
  storage_battery_setting_output: `Battery output(kW)`,
  storage_battery_setting_chrgborder: `Charging base price(Yen/kWh)`,
  storage_battery_setting_dischrgborder: `Discharging base price(Yen/kWh)`,
  storage_battery_setting_switchingtime: `Switching charge and discharge time`,
  storage_battery_setting_priceinterval: `Area price reference interval`,
  spot_price_interval_day: `1 day ago`,
  spot_price_interval_week: `1 week ago`,
  demand_setting_partial_supply_none: `No partial supply contract`,
  demand_setting_partial_supply_notice_type: `Notice type partial supply (Base)`,
  setting_warning: `Warning`,
  file_select: `File selection`,
  jepx_user_setting_message: `*Fields are required for JEPX additional users when entering ID.`,
  jepx_user_setting_required: `*Required for additional user registration`,
  power_bp_setting_title: `Power generation company setting`,
  power_bp_setting_bp_kind_1: `one's company`,
  power_bp_setting_bp_kind_2: `third party`,
  intraday_bid_delete: `Bid cancellation`,
  network_err: `Network connection error`,
  session_err: `Authentication error`,
  auto_bidding_check_title: `Auto Bidding`,
  all_areas: `All Area`,
  triple_component_all_bg: `All BG`,
  triple_component_all_power_plants: `All Power plants`,
  triple_component_all_demand: `All Demand`,
  operation_err: `Operation error`,
  power_gen_forecast: `Power generation forecast`,
  charge_and_discharge_forecast: `Discharge forcast`,
  generated_power: `Generated power`,
  charge_and_discharge_power: `Discharge power`,
  discharge_power: `Discharge power`,
  charge_power: `Charge power`,
  storage_battery_charge: `Storage battery charge`,
  own_consumption_setting_edit: `Redundancy setting`,
  own_consumption_setting_tittle: `Own consumption settings`,
  own_consumption_setting_no: `Own consumption setting no`,
  own_consumption_setting_name: `Own consumption setting name`,
  own_consumption_setting_value: `Own consumption value`,
  own_consumption: `Own consumption`,
  option: `Option`,
  certificate_password: `Certificate Password`,
  statusList_no_contract: `No contract`,
  more_than_one_area: `More than one Area`,
  only_one_area: `Only one Area`,
  specific_code: `specific code`,
  supply_destination_area: `supply destination area`,

  // メッセージ
  could_not_communicate_occto: `Could not communicate with OCCTO.`,
  bp_submit_success: `Plans have been submitted.`,
  bp_submit_failed: `There are plans that could not be submitted. Please check your setup and plan status.`,
  bp_submit_timeout: `Could not submit plan due to timeout.`,
  bp_submit_error: `Could not submit a plan.`,
  bp_confirm_success: "Plans have been `confirmed`.",
  bp_confirm_error: "I could not `confirmed` the plan.",
  bp_resume_timeout: `Could not obtain a transmission response due to timeout.`,
  bp_resume_error: `Could not obtain a transmission response.`,
  bp_notification_timeout: `Could not be obtained a Notification result due to timeout.`,
  bp_notification_error: `Could not be obtained a Notification result.`,
  bp_resume_check: `Resume retrieving outgoing responses?`,
  bp_notification_check: `Do you want to reflect the Notice of Inconsistency in your plan?`,
  bp_unplaned_msg: `Plan not completed.`,
  bp_unplaned_hint: `When the status of each plan is saved, the form is displayed on this screen.`,
  dp_not_bg_setting: `Demand BG is 0. Please check if the demand BG is set from the settings.`,
  dp_error: `Could not connect to the server. Please give it some time and try again.`,
  dp_check_power: `Cannot save the input value because it exceeds the contract power.`,
  dp_copy_error: `Cannot be copied because the generation plan is unplanned.`,
  not_power_bg_setting: `Power Generation BG is not set. Please configure the settings from the Power Generation BG Settings.`,
  not_demand_bg_setting: `Demand BG is not set. Please configure the settings from the Demand BG Settings.`,
  not_demand_setting: `Demand is not set. Please configure the settings from the Demand Settings.`,
  leave_page_defalt_msg: `I have unsaved data. Are you sure you want to move the page?`,
  date_chg_msg: `I have unsaved data. Are you sure you want to change the date?`,
  status_not_area_setting: `Area setting is not yet configured.`,
  note_no_input_: `Maximum output and proratable are registered as blank if not entered.`,
  reflected_system_planned_values: `The planned values of the selected forms are reflected in the system planned values.`,
  note_bid_cancel: `Do you wish to cancel your bid with the above information?`,
  note_bid_execute: `Would you like to save data and execute the bid with the above information?`,
  updates_selected_form_confirmed: `Do you want to update the selected form to Confirmed?`,
  note_display_when_saved: `When the status of each plan is saved, the form is displayed on this screen.`,
  note_about_unit: `All values that can be entered are in kWh.`,
  note_submit_notification: `Do you want to submit the notification changes for the selected forms?`,
  note_download_selected_forms: `Do you want to download the select forms?`,
  plan_submission_deadline_is_approaching: `The plan submission deadline is approaching.`,
  incorrect_login_information: `Incorrect login information.`,
  timeout_login_information: `Connection failed.`,
  get_latest_notification_results: `Get the latest notification results.`,
  read_the_latest_result: `Read the latest contract result`,
  plan_not_completed: `Plan not completed.`,
  note_saved: `Plans saved.`,
  note_not_saved: `Could not save plan.`,
  save_check: `There are plan values that are not entered in 50 kWh increments. Do you want to save it to be corrected in the bidding screen?`,
  bid_automation_start_failed: `Failed to start auto bidding.`,
  bid_automation_stop_failed: `Failed to stop auto bidding.`,
  bid_automation_start: `Auto bidding started.`,
  bid_automation_stop: `Auto bidding stopped.`,
  reconsider_automatic_setting: `Reconsider your automatic setting, bidding deadline and automatic deletion settings.`,
  bid_automatic_setting_saved: `Bid automatic setting is saved.`,
  bid_automatic_setting_saved_failed: `Bid automatic setting could not be saved.`,
  automatic_delete_deadline_setting_saved: `Bidding deadline and automatic deletion settings has been saved.`,
  automatic_delete_deadline_setting_saved_failed: `Bidding deadline and automatic deletion settings could not be saved.`,
  get_error: `Data could not be retrieved.`,
  saved: `Saved.`,
  saved_dupicated: `The same plan value cannot be saved.`,
  saved_failed: `Could not save.`,
  outside_limit_message: `It is outside the bidding limit.`,
  range_specification: `Enter within the range below.`,
  could_not_communicate_jepx: `Could not communicate with JEPX.`,
  contract_reflected_in_pwsp: `The contract amount has been reflected in power generation and sales plan.`,
  could_not_be_reflected_in_pwsp: `The contract amount could not be reflected in power generation and sales plan.`,
  contract_reflected_in_dempro: `The contract amount has been reflected in demand and procurement plan.`,
  could_not_be_reflected_in_dempro: `The contract amount could not be reflected in demand and procurement plan.`,
  save_sales_plan_procurement_plan: `The sales plan and procurement plan have been saved in power generation and sales plan.`,
  cannot_save_sales_plan_procurement_plan: `Failed to save sales plan and procurement plan in power generation and sales plan.`,
  canceled_bid: `The bidding has been cancelled.`,
  could_not_canceled_bid_to_timeout: `The bid could not be canceled due to a timeout.`,
  could_not_canceled_bid: `The bid could not be canceled.`,
  temporarily_save: `The bid has been temporarily saved.`,
  faild_temp_save: `Failed to temporarily save the bidding plan.`,
  outside_bid_limit: `A value outside the bid limit range has been entered.`,
  reconsider_bidding: `Reconsider your bidding plan.`,
  execute_bidding: `Your bid has been submitted.`,
  bid_failed_bid_to_timeout: `Bidding failed due to timeout.`,
  could_not_obtain_latest_results: `Could not obtain latest results.`,
  could_not_obtain_market_details: `Could not obtain market details.`,
  unable_bid: `I was unable to place a bid.`,
  intraday_auto_deletion_message: `It will be automatically canceled at the deadline.`,
  intraday_save_supply_plan: `Saved sales plan.`,
  intraday_save_failed_supply_plan: `Sales plan could not be saved.`,
  intraday_excute_bulk_bid: `Your bulk bid has been submitted.`,
  intraday_bulk_bid_failed_to_timeout: `Bulk bidding failed due to timeout.`,
  intraday_bulk_bid_failed: `I was unable to place a bulk bid.`,
  intraday_excute_bulk_cancellation: `Bulk cancellation has been executed.`,
  intraday_bulk_cancellation_failed_to_timeout: `Bulk cancellation failed due to timeout.`,
  intraday_bulk_cancellation_failed: `Unable to perform cancellation.`,
  alarm_err_message_1: `Notification name or message has not been entered.`,
  alarm_err_message_2: `Incorrect date and time entry.`,
  alarm_err_message_3: `Select the day of the week.`,
  alarm_saved_new_setting: `Added new notification settings.`,
  alarm_failed_save_new_setting: `Could not save notification settings.`,
  alarm_updated_setting: `Updated notification settings.`,
  alarm_failed_uppdate_setting: `Could not update notification settings.`,
  alarm_deleted_setting: `Removed notification settings.`,
  alarm_failed_delete_setting: `Could not delete notification settings.`,
  loss_failed_obtain_information: `Area information could not be obtained.`,
  loss_err_message_1: `The save process cannot be executed because the loss ratio value is abnormal or there are places where it has not been entered.`,
  loss_err_message_2: `There is an area where a past date has been entered for the change reflection date. The data in the area where the past date is entered will not be saved.`,
  loss_err_message_3: `Unable to save because an invalid value has been entered for the date.`,
  loss_outside_limit_message: `It is outside the loss ratio value.`,
  loss_saved_area_information: `Transmission loss information is saved.`,
  loss_failed_save_area_infomation: `Could not save due to network error.`,
  setting_change_selections_message: `There is unsaved data. Are you sure you want to change your selections?`,
  setting_failed_new_registration: `New registration failed. Check your entry.`,
  user_setting_err_role_area_plan_submitter: `Area-by-area plan submitter can be set up to one area. Please select one area.`,
  user_registered: `New user registered.`,
  user_failed_registration: `Could not register a new user.`,
  setting_failed_update: `Could not update. Please check your entry.`,
  user_updated: `Updated user information.`,
  user_failed_update: `Could not update user information.`,
  user_cannot_delete: `Cannot delete because the user is currently logged in.`,
  user_failed_delete: `Could not delete user.`,
  user_deleted: `User deleted.`,
  password_updated_success: `Password updated.`,
  password_updated_failed: `Password could not be updated.`,
  power_bg_added: `Added power generation BG.`,
  power_bg_failed_add: `Generation BG could not be added.`,
  power_bg_update: `Updated information on power generation BG.`,
  power_bg_failed_update: `Could not update information on power generation BG.`,
  power_bg_deleted: `Power generation BG removed.`,
  power_bg_failed_delete: `Generation BG could not be deleted.`,
  power_bg_duplication_message: `Duplicate powerbg code cannot be saved.`,
  setting_confirm_entry: `Confirm your entry.`,
  demand_bg_added: `New demand BG added.`,
  demand_bg_err_message_1: `Up to one demand BG can be set per area. There are overlapping demand BGs.`,
  demand_bg_failed_add: `Could not add new demand BG.`,
  demand_bg_update: `Demand BG updated.`,
  demand_bg_failed_update: `Demand BG could not be updated.`,
  demand_bg_deleted: `Removed demand BG.`,
  demand_bg_failed_delete: `Demand BG could not be removed.`,
  power_contractor_deleted: `The power contractor has been deleted.`,
  power_contractor_failed_delete: `The power contractor could not be deleted.`,
  power_contractor_added: `New power contractor added.`,
  power_contractor_err_message_1: `Up to one power contractor can be set per area. There are overlapping power contractors.`,
  power_contractor_failed_add: `Could not add new power contractor.`,
  power_contractor_updated: `The power contarctor updated.`,
  power_contractor_failed_update: `The power contractor could not be updated.`,
  power_contractor_duplication_message: `Duplicate power contractor cannot be saved.`,
  interconnection_deleted: `Deleted connection supply agreement information.`,
  interconnection_failed_delete: `Could not delete connection supply contract information.`,
  interconnection_added: `Newly added connection supply contract information.`,
  interconnection_err_message_1: `One connection supply contract can be set up per area. There is a duplicate connection supply agreement.`,
  interconnection_failed_add: `Could not add new connection supply agreement information.`,
  interconnection_updated: `Connection and supply contract information has been updated.`,
  interconnection_failed_update: `Could not update connection supply contract information.`,
  power_plant_setting_added: `New power plant added.`,
  power_plant_setting_failed_add: `Could not add new power plant.`,
  power_plant_setting_updated: `Updated power plant information.`,
  power_plant_setting_failed_update: `Power plant information could not be updated.`,
  power_plant_setting_deleted: `Deleted power plant.`,
  power_plant_setting_failed_delete: `Power plant could not be deleted.`,
  power_plant_setting_err_message_1: `There are places where the upper and lower limit settings are larger than the upper and lower limit settings.`,
  power_plant_setting_threshold_updated: `Updated thresholds.`,
  power_plant_setting_threshold_failed_update: `Threshold could not be updated due to network error.`,
  storage_battery_setting_soc_upper_greaterthan_lower: `Lower limit should be set at a value less than the upper limit.`,
  storage_battery_setting_dischrg_greaterthan_chrg: `Charge base price should be set at a value less than the discharge base price.`,
  storage_battery_setting_contractpower_greaterthanorequal_outPut: `Output should be set at a value less than or equal the contract power.`,
  storage_battery_setting_duplicated: `A duplicate battery ID setting already exists.`,
  storage_battery_setting_update: `Updated battery storage information.`,
  storage_battery_setting_failed_update: `Battery storage information could not be updated.`,
  storage_battery_setting_delete: `Deleted battery storage information.`,
  storage_battery_setting_failed_delete: `Battery storage information could not be deleted.`,
  demand_setting_failed_delete: `Demander could not be deleted.`,
  demand_setting_deleted: `Deleted demander.`,
  demand_setting_failed_update: `Demender information could not be updated.`,
  demand_setting_updated: `Updated demander infomation.`,
  demand_setting_err_message_1: `Up to one demander can be set per demand BG. Duplicate demanders exist.`,
  demand_setting_failed_add: `Could not add new demander.`,
  demand_setting_added: `New demander added.`,
  demand_setting_partial_updated: `Updated partial supply settings.`,
  demand_setting_partial_failed_update: `Could not update partial supply settings. Please check your entries.`,
  demand_setting_partial_cancellation: `The partial supply setting has been removed.`,
  demand_setting_partial_failed_cancellation: `Could not cancel partial supply setting.`,
  setting_delete_confirmation: `Execute the deletion process. Are you sure?`,
  sale_list_failed_delete: `Could not delete the sales destination group.`,
  sale_list_deleted: `Deleted sales destination group.`,
  sale_list_failed_update: `Could not update the information for the sales destination group.`,
  sale_list_updated: `Updated information on sales destination groups.`,
  sale_list_failed_add: `Could not add new sales destination group.`,
  sale_list_added: `New sales destination group added.`,
  sale_detail_added: `Newly added sales destination group breakdown.`,
  sale_detail_failed_add: `Could not add new sales destination group breakdown.`,
  sale_detail_updated: `Updated information on sales destination group breakdown.`,
  sale_detail_failed_update: `Could not update the information in the sales destination group breakdown.`,
  sale_detail_deleted: `Deleted sales destination group breakdown.`,
  sale_detail_failed_delete: `Could not delete sales destination group breakdown.`,
  jepx_user_setting_err_message_1: `Either JEPX participant information, JEPX representative user information, or additional user information is missing. Please check your password and contents.`,
  jepx_user_setting_err_message_2: `JEPX participant information or JEPX representative user information is missing. Please check your password and contents.`,
  jepx_user_representative_register: `JEPX participant and JEPX representative user information has been registered.`,
  jepx_user_failed_register: `Input information could not be saved.`,
  jepx_user_all_register: `JEPX participant information, JEPX representative user information, and additional user information are registered.`,
  jepx_additional_user_failed_register: `I registered JEPX participant information and JEPX representative user information, but could not register additional user information.`,
  certificate_failed_regidter_check_enter: `Certificate information could not be registered. Please check the information you entered.`,
  certificate_failed_regidter: `Certificate information could not be registered.`,
  certificate_register: `Certificate information has been registered.`,
  power_bp_setting_new_registered: `New power generation company has been registered.`,
  power_bp_setting_failed_register: `New power generation company could not be registered.`,
  power_bp_setting_err_message_1: `Up to one power generation company can be set per tenant.`,
  power_bp_setting_err_message_2: `Please select at least one area.`,
  power_bp_setting_updated: `Updated information power generation company.`,
  power_bp_setting_failed_update: `Could not update the information in the power generation company.`,
  power_bp_setting_deleted: `Deleted Power generation company.`,
  power_bp_setting_delete_failed: `Could not delete Power generation company.`,
  network_err_message: `Network connection error has occurred. Please try again after some time.`,
  session_err_message: `Invalid session. Please log in again.`,
  auto_bidding_start_check_msg: `Do you want to start auto bidding?`,
  auto_bidding_stop_check_msg: `Do you want to stop auto bidding?`,
  try_to_get_again: `Try again?`,
  operation_err_message: `Invalid operation performed. Please log in again.`,
  back_to_login: `Back to login`,
  download_balance_of_payments_success: `Balance of payments report downloaded.`,
  download_balance_of_payments_failed: `Balance of payments report download failed.`,
  not_exist_all_balance_of_payments_data: `No balance of payments report data for the month.`,
  not_exist_some_balance_of_payments_data: `No balance of payments report data for the month in the following areas.`,
  no_area_administrated: `Areas of responsibility is not set. Please set from the user settings.`,
  unable_to_download_balance_of_payments_report: `Unable to download balance of payments report because of no data for power generation results.`,
  check_contract_power: `Cannot be saved because the generation plan exceeding contracted power`,
  check_discharge_power: `Cannot be saved because the discharging power exceeding the rated output has been input.`,
  check_charge_power: `Cannot be saved because the charging power in excess of generated power has been entered.`,
  check_charge_upper_limit: `Cannot be saved because the storage capacity exceeding the upper storage limit.`,
  check_charge_lower_limit: `Cannot be saved because the storage capacity is below the lower storage limit.`,
  check_own_consumption: `Cannot be saved because the own consumption in excess of generated power has been entered.`,
  own_consumption_setting_saved: `Own consumption setting saved.`,
  own_consumption_setting_failed_save: `Could not save own consumption setting.`,
  own_consumption_setting_deleted: `Own consumption setting deleted.`,
  own_consumption_setting_faled_delete: `Could not delete own consumption setting.`,
  setting_can_not_select_area: `There are no area to choose from. Please check your setting.`,
  specific_code_are_duplicated: `specific code are duplicated.`,
  setting_can_not_display_bp: `There are no BP available for display. Please check your setting.`,
  setting_can_not_select_power_demand: `There are no powerplant/demand available for display. Please check your setting.`,
  setting_can_not_select_power: `There are no powerplant available for display. Please check your setting.`,
  setting_can_not_select_demand: `There are no demand available for display. Please check your setting.`,
  setting_can_not_display_salegr: `There are no sale group available for display. Please check your setting.`,
  setting_can_not_display_demandbg: `There are no demand bg available for display. Please check your setting.`,
  demand_bg_duplication_message: `Duplicate demandbg code cannot be saved.`,
  setting_can_not_select_powerbg: `There are no powerbg available for display. Please check your settings.`,
  grid_code_are_duplicated: `grid code are duplicated.`,
  power_point_number_are_duplicated: `power point number are duplicated`,
  supply_point_number_duplicated: `The supply point identification number is duplicated.`,
  setting_can_not_select_power_contractant: `There are no power contractant available for display. Please check your setting.`,
  delete_subordinate_master_confirmation: `I will also delete the lower master, is that correct?`,
  charge_and_discharge_amount: 'Legend for Discharge Power [Charge: Negative Values] [Discharge: Positive Values]',
  limit_value: 'The lower limit is set to a value greater than the upper limit.'

};
