import React, { useState, useEffect, useContext, useMemo } from "react";
import "./css/SetPowerPlantList.css";
import {
  Box,
  Divider,
  FormGroup,
  FormLabel,
  Stack,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { LanguageContext } from "../common/localization/localization";
import {
  resPowerBgInfo,
  setPowerPlantInfo as setPowerPlant,
  setProrataData,
} from "../../types/master/SetPowerPlantList";
import { addDays, format } from "date-fns";
import { resAreaList } from "../../types/common/Search";
import { TimeTable } from "../jepx/OneHourAdvanceBid/CreateTime";
import { modalMode } from "./PowerPlantList";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { usePowerPlantUtility } from "../../common/powerEnergy";
import {
  API_URL,
  AREA_ID,
  POWER_ENERGY_KIND,
  RESULT_CODE,
} from "../../common/constant";
import { useAuthedApi } from "../../common/axios";
import { CommonResponse } from "../../types/common/Api";
import {
  PowerDetail,
  SelectablePowerBg,
  resSelectablePowerBg,
} from "../../types/master/PowerPlantList";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";
import { getBusinessModel } from "../common/getBusinessModel";

//発電方式
const powerEnergyKind = [
  POWER_ENERGY_KIND.NONE,
  POWER_ENERGY_KIND.THERMAL,
  POWER_ENERGY_KIND.HYDRO,
  POWER_ENERGY_KIND.SOLAR,
  POWER_ENERGY_KIND.SMALL_HYDRO,
  POWER_ENERGY_KIND.WIND,
  POWER_ENERGY_KIND.BINARY,
  POWER_ENERGY_KIND.BIOMASS,
];

const initProrataList: setProrataData[] = [];
for (let i = 0; i < 48; i++) {
  initProrataList.push({ priority: 0, prorateNum: 0 });
} //初期化用

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  plantName: boolean;
  areaId: boolean;
  powerBgId: boolean;
  generationKindNum: boolean;
  rootCode: boolean;
  powerPointNumber: boolean;
  pannelType: boolean;
  sunPannel: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  plantName: false,
  areaId: false,
  powerBgId: false,
  generationKindNum: false,
  rootCode: false,
  powerPointNumber: false,
  pannelType: false,
  sunPannel: false,
};

const SetPowerPlantList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  plantIdEdit: number
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //発電方式のIDとnameのリスト
  const { energyOptions } = usePowerPlantUtility();

  type powerBgInfo = {
    id: number;
    name: string;
    powerBgId: number;
    areaId: string;
    areaName: string;
  };

  //初期化用
  const initInfo: PowerDetail = {
    powerId: 0, //PK
    powerName: "", //発電所名称
    areaId: "00", //エリア
    energyKind: 0, //発電方式
    powerBgId: 0, //所属発電BGId
    contractPower: 0, //契約電力
    powerRtId: "", //系統コード
    powerPlantName: "", //系統発電 所名称
    contractId2: "", //契約識別番号２
    remarks: "", //備考
    maxPower: 0, //最大出力
    powerPointNumber: "", //受電地点特定番号
    pannelType: 0, // モジュール温度上昇にかかわるパネルタイプ
    ratedOutput: "0.0", // 定格出力
    downwardPannel: 0, // 太陽光パネル
    latitude: "0",
    longitude: "0",
    acOutput: "0.0", // 定格出力(AC),
    panelTilt: 0,
    panelAzimuth: 0,
    panelPowerDecline: 0,
    panelCoe: 0,
  };

  // 発電所詳細情報
  const [powerPlantInfo, setPowerPlantInfo] = useState<PowerDetail>(initInfo);
  // 選択可能な発電BG
  const [powerBgs, setPowerBgs] = useState<SelectablePowerBg[]>([]);
  const powerBgOptions = [
    {
      id: 0,
      name: languageContext.words.setting_unselected,
      areaId: AREA_ID.NONE,
    },
    ...powerBgs,
  ];
  // 選択中の発電BG情報
  const selectedBg = useMemo(
    () => powerBgs.find((v) => v.id === powerPlantInfo.powerBgId),
    [powerBgs, powerPlantInfo]
  );
  // 太陽光パネル設置形態
  const [panelRiseTypes, setPannelRiseTypes] = useState([]);
  const riseTypeOptions = [
    { id: 0, name: languageContext.words.setting_unselected },
    ...panelRiseTypes,
  ];
  // 太陽子パネル種別
  const [panelDownwardTypes, setPannelDownwardTypes] = useState([]);
  const downwardTypeOptions = [
    { id: 0, name: languageContext.words.setting_unselected },
    ...panelDownwardTypes,
  ];

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  // 成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]);

  // 初期表示
  useEffect(() => {
    if (toOpen === true) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        (async () => {
          Promise.all([
            getPowerDetail(plantIdEdit),
            getPowerBgMst(),
            getPannelDownwardType(),
            getPannelRiseType(),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        (async () => {
          Promise.all([
            getPowerBgMst(),
            getPannelDownwardType(),
            getPannelRiseType(),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
        setPowerPlantInfo(initInfo);
        setplantKindNo(0);
      }
    } else {
      setPowerPlantInfo(initInfo);
      setPowerPlantInfo(initInfo);
      setplantKindNo(0);
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  function setPannelType(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const tmpAreaInfo = Object.assign({}, powerPlantInfo);
    tmpAreaInfo.pannelType = id;

    setPowerPlantInfo(tmpAreaInfo);
  }

  function setSunPannelType(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const tmpAreaInfo = Object.assign({}, powerPlantInfo);
    tmpAreaInfo.downwardPannel = id;

    setPowerPlantInfo(tmpAreaInfo);
  }

  /////発電所種別番号
  const [plantKindNo, setplantKindNo] = useState<number>(0);

  //////発電所種別番号ここまで

  function SetPowerKind(id: number) {
    const tmpContractorInfo = Object.assign({}, powerPlantInfo);
    tmpContractorInfo.energyKind = Number(powerEnergyKind[id]);
    // setSelContractantCode(tmpContractInfo[e].code);
    // setSelContractantNo(tmpContractInfo[e].id);

    setPowerPlantInfo(tmpContractorInfo);
  }

  /////発電方法一式

  ////////所属発電BG関連

  // 発電所詳細取得API
  function getPowerDetail(powerId: number) {
    return api
      .get<CommonResponse<PowerDetail>>(API_URL.GET_ONE_POWERPLANT_DATA, {
        params: { powerId },
      })
      .then((res) => {
        setPowerPlantInfo(res.data.result);

        //const responseData = res.data.result;

        //DBで小数点以下の0の情報が欠損しているため取得した緯度経度を小数第6位まで0でパディング
        //responseData.latitude = Number(responseData.latitude).toFixed(6);
        //responseData.longitude = Number(responseData.longitude).toFixed(6);
        //DBで小数点以下の0の情報が欠損しているため取得した定格出力を小数第4位まで0でパディング
        //responseData.ratedOutput = Number(responseData.ratedOutput).toFixed(4);

        //setgenerateWaysNo(responseData.generationKindNum);
        //setplantKindNo(responseData.powerKind);
        //setPowerIdSellector(responseData.powerBgId); //ここでは表示用データ更新
      });
  }

  // 発電BG情報取得API
  function getPowerBgMst() {
    return api
      .get<CommonResponse<resSelectablePowerBg>>(API_URL.GET_POWERBG_MST)
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.NO_POWER_BG) {
          setDialogMessage(
            languageContext.words.setting_can_not_select_powerbg
          );
          setDigOpen(true);
        }

        setPowerBgs(res.data.result);
      })
      .catch((err) => {
        setPowerBgs([]);
      });
  }

  // 太陽光パネル設置形態取得API
  function getPannelRiseType() {
    return api.get(API_URL.GET_PANNEL_RISE).then((res) => {
      setPannelRiseTypes(res.data.result);
    });
  }

  // 太陽光パネル取得API
  function getPannelDownwardType() {
    return api.get(API_URL.GET_PANNEL_DOWNWARD).then((res) => {
      setPannelDownwardTypes(res.data.result);
    });
  }

  // 発電所新規登録API
  function AddDatas() {
    if (isCorrectInfo()) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      api
        .post(API_URL.ADD_POWERPLANT_INFO, powerPlantInfo)
        .then((res) => {
          if (res.data.resultCode === RESULT_CODE.SUCCESS) {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_plant_setting_added);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(languageContext.words.grid_code_are_duplicated);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID_PPN) {
            setDialogMessage(languageContext.words.power_point_number_are_duplicated);
          }
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(
            languageContext.words.power_plant_setting_failed_add
          );
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  // 発電所更新API
  function UpdateDatas() {
    if (isCorrectInfo()) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      api
        .post(API_URL.UPDATE_POWERPLANT_INFO, powerPlantInfo)
        .then((res) => {
          if (res.data.resultCode === RESULT_CODE.SUCCESS) {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_plant_setting_updated);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(languageContext.words.grid_code_are_duplicated);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID_PPN) {
            setDialogMessage(languageContext.words.power_point_number_are_duplicated);
          }
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(
            languageContext.words.power_plant_setting_failed_update
          );
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  // 発電所削除API
  async function Delete(powerId: number | undefined) {
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_POWERPLANT_INFO, { powerId })
      .then((res) => {
        setJobClear(true);
        setDialogMessage(languageContext.words.power_plant_setting_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(
          languageContext.words.power_plant_setting_failed_delete
        );
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  }

  const convertPowerBgSelecterDatas = (result: resPowerBgInfo[]) => {
    const retPowerBgData: powerBgInfo[] = [];
    result.map((data, index) => {
      const contractorData: powerBgInfo = {
        id: 0,
        name: "",
        powerBgId: 0,
        areaId: "00",
        areaName: "",
      };
      contractorData.id = index + 1; //0要素にはデフォルトのものが入るため
      contractorData.name = data.name;
      contractorData.powerBgId = data.powerBgId;
      contractorData.areaId = data.areaId;
      contractorData.areaName = data.areaName;

      retPowerBgData.push(contractorData);
    });
    return retPowerBgData;
  };

  ////////所属発電BG

  const initProratalList: setProrataData = {
    priority: 1,
    prorateNum: 1,
  };

  //太陽光パネルの設置方式リストを作成
  const createPanelInstallationList = () => {
    const panelInstallation = [
      { id: 0, name: languageContext.words.panel_mounted_type_1 },
      { id: 1, name: languageContext.words.panel_mounted_type_2 },
      { id: 2, name: languageContext.words.panel_mounted_type_3 },
      { id: 3, name: languageContext.words.panel_mounted_type_4 },
    ];
    return panelInstallation;
  };

  //太陽光パネルの種類のリストを作成
  const createPanelKindList = () => {
    const panelKind = [
      { id: 0, name: languageContext.words.panel_type_1 },
      { id: 1, name: languageContext.words.panel_type_2 },
      { id: 2, name: languageContext.words.panel_type_3 },
      { id: 3, name: languageContext.words.panel_type_4 },
    ];
    return panelKind;
  };

  //テキストフィールドの中身が変化したら、powerContractorInfoの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    let value: string = e.currentTarget.value;
    // let pos: number = 0;
    // if(e.currentTarget.selectionStart != null) {
    //   pos = e.currentTarget.selectionStart;
    // }

    const tmpInfo = Object.assign({}, powerPlantInfo);
    switch (name) {
      case "plantName":
        tmpInfo.powerName = value;
        break;
      case "contrantPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.contractPower = parseInt(value);
        }

        break;
      case "rootCode":
        tmpInfo.powerRtId = value;
        break;
      case "rootPlantName":
        tmpInfo.powerPlantName = value;
        break;
      case "rootPlantCode2":
        tmpInfo.contractId2 = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      case "maxPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.maxPower = parseInt(value);
        }

        //tmpInfo.maxPower = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      case "powerPointNumber":
        if (value === "") {
          value = "0";
        }
        tmpInfo.powerPointNumber = value;
        break;
      case "latitude":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 90, 0) &&
          !value.match(/^[-]?\d*(\.\d\d\d\d\d\d\d+)+/)
        ) {
          tmpInfo.latitude = value;
        }
        break;
      case "longitude":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 180, 0) &&
          !value.match(/^[-]?\d*(\.\d\d\d\d\d\d\d+)+/)
        ) {
          tmpInfo.longitude = value;
        }
        break;
      case "ac_output":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 999999, 0) &&
          !value.match(/^\d*(\.\d\d\d\d\d+)+/)
        ) {
          tmpInfo.acOutput = value;
        }
        break;

      case "panel_tilt":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 90, 0)) {
          tmpInfo.panelTilt = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "panel_azimuth":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 90, 0)) {
          tmpInfo.panelAzimuth = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "panel_power_decline":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.panelPowerDecline = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "panel_coe":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.panelCoe = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "rated_output":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 999999, 0) &&
          !value.match(/^\d*(\.\d\d\d\d\d+)+/)
        ) {
          tmpInfo.ratedOutput = value;
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      default:
        break;
    }
    setPowerPlantInfo(tmpInfo);
  }

  //テキストフィールドに正しい数値チェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseFloat(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerPlantInfo?.powerName == "") {
      error = true;
      tempInputErrors.plantName = true;
    }
    if (powerPlantInfo?.areaId === AREA_ID.NONE) {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (!powerPlantInfo?.powerBgId) {
      error = true;
      tempInputErrors.powerBgId = true;
    }
    if (!powerPlantInfo?.energyKind) {
      error = true;
      tempInputErrors.generationKindNum = true;
    }
    if (!powerPlantInfo?.powerRtId) {
      error = true;
      tempInputErrors.rootCode = true;
    }
    if (!powerPlantInfo?.powerPointNumber) {
      error = true;
      tempInputErrors.powerPointNumber = true;
    }

    if (!powerPlantInfo?.pannelType) {
      error = true;
      tempInputErrors.pannelType = true;
    }

    if (!powerPlantInfo?.downwardPannel) {
      error = true;
      tempInputErrors.sunPannel = true;
    }
    setInputError(tempInputErrors);
    return error;
  };

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    text: PowerDetail,
    setText: React.Dispatch<React.SetStateAction<PowerDetail>>
  ) => {
    const data = e.currentTarget.value;
    const name = e.currentTarget.name;
    const tempText = structuredClone(text);
    switch (name) {
      case "latitude":
        tempText.latitude = Number(data).toFixed(6);
        break;
      case "longitude":
        tempText.longitude = Number(data).toFixed(6);
        break;
      case "rated_output":
        tempText.ratedOutput = Number(data).toFixed(4);
        break;
      case "ac_output":
        tempText.acOutput = Number(data).toFixed(4);
        break;
      case "panel_power_decline":
        tempText.panelPowerDecline = Math.floor(Number(data) * 100) / 100;
        break;
      case "panel_coe":
        tempText.panelCoe = Math.floor(Number(data) * 100) / 100;
        break;
      default:
        break;
    }
    setText(tempText);
  };

  const DoModeJob = () => {
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.power_plant_list_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(powerPlantInfo?.powerId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack spacing={0} direction={"row"} sx={{ width: "1106px" }}>
        <Box sx={{ height: "630px", width: "550px" }}>
          <CustomBoxBig>
            <FormLabel required>
              {languageContext.words.power_plant_name}
            </FormLabel>
            <CustomTextFieldBig
              type="text"
              value={powerPlantInfo?.powerName}
              onChange={onChangeValue}
              name="plantName"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.plantName}
            ></CustomTextFieldBig>
          </CustomBoxBig>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.affiliation_power_bg}
                </FormLabel>
              </FormGroup>
              <CustomSelectListBig
                name="powerBg"
                label={languageContext.words.area_unselected}
                value={powerPlantInfo.powerBgId}
                options={powerBgOptions}
                disabled={mode === modalMode.editMode}
                onChange={(e: number) => {
                  setPowerPlantInfo((prev) =>
                    prev
                      ? {
                          ...prev,
                          powerBgId: e,
                          areaId:
                            powerBgs.find((v) => v.id === e)?.areaId ??
                            AREA_ID.NONE,
                        }
                      : prev
                  );
                }}
                error={inputError.powerBgId}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.area}
              </CustomModalTypography>

              <CustomTextFieldSmall
                //key={"demandForecastData_CustomSelectList_customer"}
                name="areaId"
                value={convertIdToAreaName(selectedBg?.areaId ?? AREA_ID.NONE)}
                disabled={true}
                error={inputError.areaId}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.power_gen_method}
                </FormLabel>
              </FormGroup>
              <CustomSelectListSmall
                name="generationWay"
                value={powerPlantInfo?.energyKind}
                options={energyOptions(powerEnergyKind)}
                onChange={(e: number) => {
                  SetPowerKind(e);
                }}
                error={inputError.generationKindNum}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.contracted_power +
                    "(" +
                    languageContext.words.kw +
                    ")"}
                </FormLabel>
              </FormGroup>
              <CustomTextFieldSmall
                type="number"
                value={Number(powerPlantInfo?.contractPower).toFixed(0)}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.contractPower) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="contrantPower"
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>{languageContext.words.grid_code}</FormLabel>
              <CustomTextFieldSmall
                type="text"
                value={powerPlantInfo?.powerRtId}
                onChange={onChangeValue}
                name="rootCode"
                inputProps={{
                  maxLength: 5,
                }}
                error={inputError.rootCode}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.plant_name_for_note_form}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerPlantInfo?.powerPlantName}
              onChange={onChangeValue}
              name="rootPlantName"
              inputProps={{
                maxLength: 64,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.power_receive_point_number}
              </FormLabel>
              <CustomTextFieldSmall
                type="text"
                value={powerPlantInfo?.powerPointNumber}
                onChange={onChangeValue}
                name="powerPointNumber"
                inputProps={{
                  maxLength: 22,
                }}
                error={inputError.powerPointNumber}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {
                  languageContext.words
                    .contract_id_2_for_power_gen_and_sales_plan
                }
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={powerPlantInfo?.contractId2}
                onChange={onChangeValue}
                name="rootPlantCode2"
                inputProps={{
                  maxLength: 20,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.remarks}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerPlantInfo?.remarks}
              onChange={onChangeValue}
              name="remarks"
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            height: "630px",
            width: "550px",
            marginLeft: "8px",
          }}
        >
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.setting_latitude}
              </FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.latitude}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.latitude) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (event.key === "e" || event.key === "E") {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="latitude"
                inputProps={{
                  step: 0.000001,
                  min: 0,
                  max: 90,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.setting_longitude}
              </FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.longitude}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.longitude) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (event.key === "e" || event.key === "E") {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="longitude"
                inputProps={{
                  step: 0.000001,
                  min: 0,
                  max: 180,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>{languageContext.words.panel_tilt}</FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.panelTilt}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.panelTilt) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="panel_tilt"
                inputProps={{
                  step: 1,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 90,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.panel_azimuth}
              </FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={Number(powerPlantInfo?.panelAzimuth).toFixed(0)}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.panelAzimuth) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="panel_azimuth"
                inputProps={{
                  step: 1,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 90,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.maximum_output_kw}
              </FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={Number(powerPlantInfo?.maxPower).toFixed(0)}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.maxPower) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="maxPower"
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.rated_output}
              </FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.ratedOutput}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.ratedOutput) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="rated_output"
                inputProps={{
                  step: 0.001,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999999.999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>{languageContext.words.ac_output}</FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.acOutput}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.acOutput) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="ac_output"
                inputProps={{
                  step: 0.001,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999999.999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.panel_power_decline}
              </FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.panelPowerDecline}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.panelPowerDecline) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="panel_power_decline"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999.99,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>{languageContext.words.panel_coe}</FormLabel>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo?.panelCoe}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.panelCoe) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="panel_coe"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999.99,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.pannel_type}
                </FormLabel>
              </FormGroup>
              <CustomSelectListSmall
                //key={"demandForecastData_CustomSelectList_customer"}
                name="pannelType"
                //defaultValue={0}
                value={powerPlantInfo?.pannelType}
                options={riseTypeOptions}
                onChange={(e: number) => {
                  setPannelType(e); // データの更新
                }}
                error={inputError.pannelType}
              />
            </CustomBoxSmall>

            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.sun_pannel}
                </FormLabel>
              </FormGroup>
              <CustomSelectListSmall
                name="sunPannel"
                value={powerPlantInfo?.downwardPannel}
                options={downwardTypeOptions}
                sx={{ width: "320px" }}
                onChange={(e: number) => {
                  setSunPannelType(e); // データの更新
                }}
                error={inputError.sunPannel}
              />
            </CustomBoxSmall>
          </Stack>
        </Box>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={languageContext.words.power_plant_list_setting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerPlantList;
